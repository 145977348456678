import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArmilisService {
  constructor(private readonly http: HttpClient) {}

  transactionVerify(
    txid: string
  ): Observable<any> {
    return this.http.post<any>(
      `https://serving.armilis.com/api/v0.3a/transactionverify`,
      {
        txid,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
  }
}
