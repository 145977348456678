import { LevelService } from '../services/level.service';
import { Observable, forkJoin, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UserDataService } from '../services/users.service';
import { ProfileService } from '../services/profileService';
import { DataSetService } from '../services/dataset.service';
import { CompanyService } from '../services/companyService';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataSet } from '../models/dataset';
import { NavService } from '../services/nav.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { UsersLogsComponent } from '../modals/users-logs/users-logs.component';
import { TranslatorService } from '../services/translate.service';
import { ActionService } from '../services/action.service';
import { UserSessionService } from '../services/user-session.service';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-users-external',
  templateUrl: './users-external.component.html',
  styleUrls: ['./users-external.component.scss']
})
export class UsersExternalComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  private onDestroy = new Subject<void>();
  private languageChange = new Subject<void>();

  displayedColumns = ['id', 'name', 'locale', 'email', 'createdAt', 'lastlogin'];
  dataSource: any;
  licences: any = [];
  profiles: any = [];
  datasets: any = [];
  companies: any = [];
  levels: any = [];
  kwSearch: any;

  title = "Utenti";
  subTitle = "EDAC - Utenti"

  dropdownListCompanies: any[] = [];
  selectedCompanies: any[] = [];
  dropdownCompaniesSettings: IDropdownSettings = {};

  dropdownListProfiles: any[] = [];
  selectedProfiles: any[] = [];
  dropdownProfilesSettings: IDropdownSettings = {};

  dropdownListLicences: any[] = [];
  selectedLicences: any[] = [];
  dropdownLicencesSettings: IDropdownSettings = {};

  dropdownListDomains: any[] = [];
  selectedDomains: any[] = [];
  dropdownDomainsSettings: IDropdownSettings = {};

  dropdownListlevels: any;
  selectedLevel: any;
  dropdownLevelSettings: IDropdownSettings = {};

  selectedUser: any;

  activeUser: any;
  sort: MatSort
  langChangeSubscription: any;

  limitedProfileCodes: any = [
    'PRD-OP',
    'PRD-OP-LEADER',
    'MLT-OP',
    'GRD-OP',
    'PRD-CO',
    'ENE-OP',
    'SLS-TC'
  ]
  profileNotForAreaManager: any = [
    'HQ-DIR',
    'HQ-IT',
    'HQ-BRD'
  ]

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }


  searchTimeout: any;

  @ViewChild('closeModalProfiles') closeModalProfiles: ElementRef;

  constructor(
    private userDataService: UserDataService,
    private dataSetService: DataSetService,
    private profileService: ProfileService,
    private companyService: CompanyService,
    public libService: LibService,
    public navService: NavService,
    private toastr: ToastrService,
    private levelService: LevelService,
    public dialog: MatDialog,
    public translate: TranslatorService,
    public MatPaginatorIntl: MatPaginatorIntl,
    public actionService: ActionService,
    public userSessionService: UserSessionService,

  ) {
    this.dataSource = new MatTableDataSource();

    //this.profileService.getAllProfiles().subscribe(r => this.data = r);
  }

  ngOnInit(): void {


    this.setTranslationsItemsPerPageLabel();
    this.loadUsers();

    // Ascolta i cambiamenti di lingua
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTranslationsItemsPerPageLabel();
      this.languageChange.next();
    });

    this.languageChange.subscribe(() => {
      this.updateTranslations();
    });

    let actions: any;
    actions = [
      // { name: 'upload', url: '' },
      // { name: 'export', url: '' },
    ];
    this.navService.setActions(actions);
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');



    this.selectedProfiles = [
    ];

    this.dropdownProfilesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleziona Tutti',
      unSelectAllText: 'Deseleziona Tutti',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessun profilo disponibile',
      allowSearchFilter: true
    };



    this.initColumns()
  }

  initColumns() {

    this.displayedColumns = ['id', 'name', 'locale', 'email', 'createdAt', 'lastlogin'];

    if (this.canAssignUserProfiles()) this.displayedColumns.push('profile')

    this.displayedColumns.push('saveAction')
  }


  checkPermissions(itemType: any, actionType: any) {
    return this.actionService.checkPermissions(`${itemType}_${actionType}`)
  }


  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  setTranslationsItemsPerPageLabel() {
    this.MatPaginatorIntl.itemsPerPageLabel = this.translate.instant('LABEL.ELEMENTI_PER_PAGINA');
    this.MatPaginatorIntl.nextPageLabel = this.translate.instant('LABEL.PROSSIMA_PAGINA');
    this.MatPaginatorIntl.previousPageLabel = this.translate.instant('LABEL.PAGINA_PRECEDENTE');
    this.MatPaginatorIntl.changes.next();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  updateTranslations() {
    this.dropdownListProfiles = this.getProfiles();

    // Aggiorna anche le traduzioni visualizzate nella tabella, se necessario
    this.dataSource.data = this.dataSource.data.map((user: { profileName: string;}) => {
      return {
        ...user,
        profileName: this.translate.instant('WORKTYPE_PROFILES.' + user.profileName),
        // Continua a tradurre altri campi necessari
      };
    });
  }


  getProfiles() {

    return this.profiles;
  }



  noEdit(element: any) {
    // console.log('element %o activeUser %o', element, this.activeUser);
    if (element == null)
      return true;
    if (this.activeUser == null)
      return true;
    if (this.activeUser.id == element.id)
      return false;
    else
      return true;
  }

  onSelectAll(items: any) {
    console.log(items);
  }



  updateUserData() {
    this.libService.lockPage('')
    const curr = this.paginator.pageIndex * this.paginator.pageSize
    const size = this.paginator.pageSize
    const observables = []
    const displayed = this.dataSource?.sortData(this.dataSource.filteredData, this.dataSource.sort)
    const fullData = this.dataSource.data
    const filter = this.dataSource.filter

    const indexesToFind = []
    for (let i = curr; i < curr + size; i++) {
      indexesToFind.push(displayed[i]?.id)
    }


    const indexes: number[] = []
    indexesToFind.forEach(iToFind => {
      for (let i = 0; i < fullData?.length; i++) {
        if (iToFind == fullData[i].id) {
          indexes.push(i)
          return
        }
      }
    })

    for (let i = 0; i < size; i++) {
      if (fullData[indexes[i]]?.id)
        observables.push(this.userDataService.getExternalUser(fullData[indexes[i]].id))
    }
    if (observables.length == 0) this.libService.unlockPage()
    forkJoin(observables).subscribe(fullusers => {
      for (let i = 0; i < size; i++) {
        if (fullusers[i]) fullData[indexes[i]] = fullusers[i]
      }
      this.dataSource.data = fullData
      this.libService.unlockPage()
    })
  }

  search(e: any) {
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
    else this.dataSource.filter = ''

    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => this.updateUserData(), 1000)
  }

  loadUsers(): void {

    this.libService.lockPage('');
    this.companyService.getAllCompaniesFast()?.subscribe((response5) => {
      this.userDataService.indexExt().subscribe((response) => {
        console.log('loadUsers response %o', response);
        if (response.length > 0) {
          this.dataSource.data = response;
          this.libService.unlockPage();
          this.updateUserData()

        }       
      });
    });
    this.profileService.getAllProfilesFast().subscribe((response3) => {
      console.log('loadProfiles response %o', response3);
      if (response3.length > 0) {
        this.profiles = response3;
        this.dropdownListProfiles = this.getProfiles();
      }
    })

  }


  reloadUserData(user: any) {
    this.activeUser = null
    this.userDataService.getExternalUser(user.id).subscribe((dbUser) => {
      this.dataSource.data[this.dataSource.data.findIndex((el: any) => el.id === user.id)] = dbUser;
      this.dataSource.data = new Array(...this.dataSource.data);
    })
  }


  saveUser(user: any) {
    console.log('save User %o activeUser %o', user, this.activeUser);
    console.log('Profili %o', this.selectedProfiles);


    user.profiles = this.selectedProfiles.map(profile => {
      return profile.id
    })

    // user.companies = this.selectedCompanies.map(company => {
    //   return company.id
    // })

    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].id == user.id) this.dataSource.data[i] = user
    }
    console.log(user)
    //console.log('user for update %o',user);
    this.libService.lockPage('');

    this.userDataService.setExtProfiles(user).subscribe(res1 => {
      this.libService.unlockPage();
      this.reloadUserData(user);
    },
      error => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE34') + error.message, this.translate.instant('TOASTR.ERROR'));
      });
  }

  setActiveUser(element: any) {
    this.activeUser = element;
    //console.log('active user %o', element);
  }

  mapsState(user: any) {
    this.selectedDomains = user.UsersDatasetsRel
      ?.filter((up: any) => up.UsersDatasets.deletedAt == null)
      .map((dataset: any) => {
        return { id: dataset.id, name: this.translate.instant('WORKTYPE_PROFILES.' + dataset.name) }
      });
  }


  selectProfile(user: any) {
    console.log('user_', user)
    this.mapsState(user);
    
    this.selectedProfiles = user.profiles.map((dataset: DataSet) => {
      return { id: dataset.id, name: dataset.name }
    })
    
  }


  canAssignUserProfiles() {
    return this.actionService.checkPermissions(`actions_users_canAssignUserProfiles`)
  }



}
