<div>
  <br>
  <br>
  <div class="pecFilter">
    <input *ngIf="withSearchBar" type="text" class="form-control item" [placeholder]="translate.instant('FORM_FIELD.SEARCH')" [(ngModel)]="search"
    [value]="search">
    <button *ngIf="withSearchBar" class="btn btn-primary btn-sm me-3 text-dark item" (click)="onFilter()">
      <div>{{'LABEL.SEARCH' | translate}}</div>
    </button>
    <button *ngIf="withSearchBar" class="btn btn-primary btn-sm me-3 redButton" (click)="reset()" color="warn">
      <div> {{'JOURNEYS.reset' | translate}}</div>
    </button>
  </div>
  <div>
    <br>
  </div>
 

  <br>

  <div class="d-flex flex-row">
    <div class="col-4">
      <button *ngIf="buttonVisibility()" class="btn btn-primary btn-sm me-3 text-dark reviewAdd" (click)="openDialog({})">
        <div>{{'LABEL.AggiungiNuovo' | translate}}</div>
      </button>
    </div>
    <div class="col-4">
      <select *ngIf="withFilter" class="form-select" name="team-office" id="team-office" [(ngModel)]="pecStatusFilter" (change)="pecStatusFilterChange()">
        <option [ngValue]="null" >Visualizza tutti gli stati delle PEC</option>
        <option [value]="status.value" *ngFor="let status of pecStatusFilterList; let iC = index">
          {{ status.text }}
        </option>
      </select>
    </div>
  </div>
  <br>





  <app-data-table-with-pagination [data]="items" [columns]="columns" [displayedColumns]="displayedColumns"
    [actions]="actions" [totalRows]="totalRows" [pageInit]="pageInit" [currentPage]="currentPage"
    (changePagination)="pageChanged($event)" (changeOrder)="updateDataBySortParams($event)"
    sortActive=""></app-data-table-with-pagination>

</div>