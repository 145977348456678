<h1 mat-dialog-title>{{ titleLabel | translate }}</h1>

<div mat-dialog-content>

  <p>
    {{ textLabel | translate }}
  </p>

  <form [formGroup]="form" class="form-container" *ngIf="form">

    <mat-form-field style="width: 100%;" appearance="fill"
      class="form-activities">
      <mat-label>{{ 'LABEL.type' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="EventDocumentTypeId">
        <mat-option *ngFor="let eventDocumentType of eventDocumentTypes" [disabled]="eventDocumentType.disabled"
          [value]="eventDocumentType.id">
          {{ eventDocumentType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div appearance="fill" class="form-subject mat-form-field" style="width: 100%;padding-bottom:1.34375em;">
      <input type="file" id="fileInput" (change)="onFileSelected($event)" class="file-input" />
    </div>


    <mat-form-field appearance="fill" class="form-subject" style="width: 100%;">
      <mat-label>{{ 'LABEL.name' | translate }}</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" style="width: 100%;">
      <mat-label>{{ 'LABEL.description' | translate }}</mat-label>
      <textarea matInput rows="15" formControlName="description" placeholder="Descrizione"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" style="width: 100%;">
      <mat-label>{{ 'LABEL.POSITION' | translate }}</mat-label>
      <input type="number" matInput formControlName="order">
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="disabledConfirmButton()">{{ confirmButtonLabel |
    translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
</div>