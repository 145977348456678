import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { of, Observable, tap, map } from 'rxjs';
import { ContactReason } from 'src/app/models/contact-reason';
import { OriginService } from 'src/app/services/origin.service';
import { InspectionContactService } from 'src/app/services/inspection-contact.service';
import { RoleService } from 'src/app/services/role.service';
import { SubjectService } from 'src/app/services/subject.service';
import { ChannelService } from 'src/app/services/channel.service';
import { StateService } from 'src/app/services/state.service';

import { OrderService } from 'src/app/services/order.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-request-data',
  templateUrl: './request-data.component.html',
  styleUrls: ['./request-data.component.scss']
})
export class RequestDataComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() practiceCode: any;
  @Input() components: any;
  @Input() users: any;
  @Input() edit: boolean;
  @Input() loggedUser: any;
  @Input() companies: any;
  @Input() isNew: boolean;
  @Output() hideSaveButton = new EventEmitter;

  contactReason: any = null;
  showLink: boolean = false;
  hide: boolean = false;
  orderCode: string;
  requestId: any = null;
  newSection: any = true;

  types$ = this.subjectService.getAllTypes()

  origins$ = this.originService.getAllOrigins();
  inspectionContacts$ = this.inspectionContactService.getAllInspectionContacts();


  contactChannels$ = this.channelService.getAllChannels();


  constructor(
    public roleService: RoleService,
    private router: Router,
    private subjectService: SubjectService,
    private originService: OriginService,
    private channelService: ChannelService,
    private orderService: OrderService,
    private stateService: StateService,
    private inspectionContactService: InspectionContactService,
    private route: ActivatedRoute,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

    // AL_EDAC -> Recupera il valore del parametro "edacDev" passato nell'url per la visulizzazione in Dev di parti di codice in sviluppo.
    this.route.queryParams
      .subscribe((params: any) => {
        if (params.edacDev) {
          this.newSection = [true, 'true'].includes(params.edacDev) ? true : false;
        }
      }
      );

    this.form.get('subject.type')?.valueChanges.subscribe(value => {
      value !== 'Privato' && value !== 'Amministratore' ?
        this.form.get('subject.surname')!.clearValidators() :
        this.form.get('subject.surname')!.setValidators(Validators.required);
      this.form.updateValueAndValidity();
    });
    this.form.get('contactReason')?.valueChanges.subscribe(contactReason => {
      console.log('contactReason')
      console.log(contactReason)
      contactReason?.id === 3 ? this.hideSaveButton.emit(false) : this.hideSaveButton.emit(true);
      this.contactReason = contactReason;
      this.onContactReasonChange(this.contactReason)
    });

    this.form.get('id')?.valueChanges.subscribe(idReq => {
      if (!([null, undefined].includes(idReq))) {
        this.requestId = idReq
      }
    })

    this.form.get('inspectionContact')?.valueChanges.subscribe(inspectionContactValue => {

      let tmp_inspectionContactId = this.form.controls['inspectionContactId'].value

      if (![undefined, null].includes(inspectionContactValue) && [undefined, null].includes(tmp_inspectionContactId)) {
        this.form.controls['inspectionContactId'].setValue(inspectionContactValue.id)
      }
    });

  }

  onContactReasonChange(contactReason: any) {
    console.log("Contact Reason -> ", contactReason);
    if (contactReason) {
      //this.clearFormValidators();
      let controlArrayRequired: (AbstractControl | null)[] = []
      let controlArrayNotRequired: (AbstractControl | null)[] = []


      switch (contactReason.name) {
        case 'Richiesta preventivo':
          controlArrayNotRequired.push(this.form.get('order')!.get('code'));
          controlArrayNotRequired.push(this.form.get('solicitReason'));

          controlArrayNotRequired.push(this.form.get('complaintType'))
          break;
        case 'Sollecito Preventivo':

          controlArrayNotRequired.push(this.form.get('complaintType'))

          controlArrayRequired.push(this.form.get('order')!.get('code'));
          controlArrayRequired.push(this.form.get('solicitReason'));
          this.loggedUser !== undefined && this.loggedUser !== null ?
            this.checkVisibilitySolicit() : console.log("NOVISIBILITY");
          break;

        case 'Reclamo':
          // controlArrayRequired.push(this.form.get('order.id'))
          controlArrayRequired.push(this.form.get('complaintType'))

          controlArrayNotRequired.push(this.form.get('solicitReason'));
          break;
      }


      controlArrayRequired.forEach(control => {
        control?.setValidators([Validators.required])
        control?.updateValueAndValidity()
      })

      controlArrayNotRequired.forEach(control => {
        control?.setValidators([])
        control?.updateValueAndValidity()
      })
    }
  }

  clearFormValidators() {
    let controls = Object.keys(this.form.controls).filter(control => control !== 'contactReason')
    console.log('clearFormValidators: ', controls);

    controls.forEach(controlName => {
      this.form.get(controlName)?.clearValidators();
      this.form.get(controlName)?.updateValueAndValidity();
    });
  }

  compareFn(o1: ContactReason, o2: ContactReason) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  checkVisibilitySolicit() {
    this.roleService.getMainRole(this.loggedUser);
    let companyLocal: any;
    let building = this.form.getRawValue().building;
    let userProfiles = this.loggedUser[0].UsersProfilesRel.map((a: any) => a.name);
    let acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    if (this.loggedUser !== undefined) {
      switch (this.loggedUser[0].role) {
        case 'Tecnico Commerciale':
          companyLocal = this.companies.find((company: any) => company.code === building?.company);
          // companyLocal !== undefined?

          console.log("USERS PROFILES --> ", userProfiles);

          if (!this.isNew) {
            if (userProfiles.includes('BackOffice')) {
              // SOLO PER TC CHE HANNO ANCHE PROFILO BACKOFFICE
              this.edit = true;
              let building = this.form.get('building')?.value;
              companyLocal = this.companies.find((company: any) => company.code === building?.company);
              Object.keys(this.form.controls).forEach(key => {
                this.form.get(key)?.disable({ emitEvent: false });
                this.form.get('subject.company')?.disable();
              });
              if (acceptedCompanies.includes(companyLocal?.code) || this.form.get('assignmentOperator')?.value === this.loggedUser[0].email) {
                this.form.get('assignmentOperator')?.enable();
              }
            } else {
              this.form.get('assignmentOperator')?.value === this.loggedUser[0].email ? undefined :
                Object.keys(this.form.controls).forEach(key => {
                  this.form.get(key)?.disable({ emitEvent: false });
                  this.form.get('subject.company')?.disable();
                  this.edit = true;
                });
            }
          } else {
            this.form.patchValue({ assignmentOperator: this.loggedUser.email })
          }
          // this.isNew ?
          //    : undefined;

          break;
        case 'BackOffice':
          if (!this.isNew) {
            this.edit = true;
            let building = this.form.get('building')?.value;
            companyLocal = this.companies.find((company: any) => company.code === building?.company);
            Object.keys(this.form.controls).forEach(key => {
              this.form.get(key)?.disable({ emitEvent: false });
              this.form.get('subject.company')?.disable();
            });
            if (acceptedCompanies.includes(companyLocal?.code) || this.form.get('assignmentOperator')?.value === this.loggedUser[0].email) {
              this.form.get('assignmentOperator')?.enable();
            }

          }
          break;

        case 'Responsabile Area': case 'Affiliato':
          companyLocal = this.companies.find((company: any) => company.code === building?.company);
          // if (this.isNew === false) {
          // acceptedCompanies.includes(companyLocal?.code) || this.form.get('assignmentOperator')?.value === this.loggedUser[0].email ? undefined :
          Object.keys(this.form.controls).forEach(key => {
            this.form.get(key)?.disable({ emitEvent: false });
            this.form.get('subject.company')?.disable();
          })
          this.form.get('note')?.enable();
          this.form.get('solicitHandled')?.enable();
          this.form.get('solicitReason')?.disable();

          // }

          break;
        case 'Area Manager': case 'Direzione':
          // this.save = true;
          break;

        case 'Admin':
          // this.save = true;
          this.edit = false;
          break;

        case 'Coordinatore':
          Object.keys(this.form.controls).forEach(key => {
            this.form.get(key)?.disable({ emitEvent: false });
            this.form.get('subject.company')?.disable();
          })
          break;
        case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
          companyLocal = this.companies.find((company: any) => company.code === building?.company);
          if (this.isNew === false) {
            acceptedCompanies.includes(companyLocal?.code) || this.form.get('assignmentOperator')?.value === this.loggedUser[0].email ? undefined :
              Object.keys(this.form.controls).forEach(key => {
                this.form.get(key)?.disable({ emitEvent: false });
                this.form.get('subject.company')?.disable();
              })
          }
          break;
        case '' || null || undefined:
          // this.save = false;

          break;

        default:
          // this.save = false;

          break;
      }
    }
  }

}
