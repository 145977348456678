import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service'


@Injectable({
    providedIn: 'root'
})
export class UserCheckinService {

    baseApi = environment.rootApiPaperwork;

    constructor(
        public libService: LibService,
        private authService: AuthService,

    ) { }

    getUserCheckins(): Observable<any> {
        return this.libService.getData<any>(
            this.baseApi,
            'orderUsersDPICheckListHeader'
        );
    }

    // Metodo per ottenere i check-in con paginazione
    getCheckinsWithPagination(filters: any, skip: number, take: number): Observable<any> {
        let tokenId = this.authService.idToken()
        const params = { filters, skip, take };
        return this.libService.postDataWithAccessToken<any>(tokenId, params, this.baseApi, `orderUsersDPICheckListHeader/paginated`);
    }

    getUserCheckinById(id: number): Observable<any> {
        return this.libService.getData<any>(
            this.baseApi,
            `orderUsersDPICheckListHeader/${id}`
        );
    }

    createUserCheckin(payload: any): Observable<any> {
        return this.libService.postData<any>(
            this.baseApi,
            'orderUsersDPICheckListHeader',
            payload
        );
    }

    updateUserCheckin(id: number, payload: any): Observable<any> {
        return this.libService.putData<any>(
            this.baseApi,
            `orderUsersDPICheckListHeader/${id}`,
            payload
        );
    }
}
