import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  SidebarComponent,
  NodeSelectEventArgs,
  TreeViewComponent,
} from '@syncfusion/ej2-angular-navigations';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../auth/auth.service';
import {
  faChevronRight,
  faSignOut,
  faSignIn,
  faBars,
  faCircle,
  faCog,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';
import { SidebarService } from '../services/sidebar.service';
import { UserSessionService } from '../services/user-session.service';
import {
  catchError,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
} from 'rxjs';
import { LibService } from '../services/libService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../services/profileService';
import { ControllerService } from '../services/controller.service';
import { User } from '../models/user';
import { Action } from '../models/action';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

export interface MenuItem {
  label: string;
  auth: boolean;
  items?: SubMenuItem[];
}

export interface SubMenuItem {
  label: string;
  url: string;
}

@Component({
  selector: 'app-sidebar-with-sub-menu',
  templateUrl: './sidebar-with-sub-menu.component.html',
  styleUrls: ['./sidebar-with-sub-menu.component.scss'],
})
export class SidebarWithSubMenuComponent implements OnInit {
  faChevronRight = faChevronRight;
  faSignOut = faSignOut;
  faSignIn = faSignIn;
  faBars = faBars;
  faCircle = faCircle;
  faCog = faCog;
  faArrowRightFromBracket = faArrowRightFromBracket;
  usrs!: Observable<any>;
  visibilities: any;
  userActions: Action[];
  loading: any = true;

  panelOpenState = false;

  @ViewChild('tree')
  public tree!: TreeViewComponent;
  @ViewChild('sidebar') sidebar!: SidebarComponent;
  public type: string = 'Push';
  public target: string = 'content';
  public enablePersistence: boolean = true;
  @ViewChild('togglebtn')
  public togglebtn!: ButtonComponent;
  public onCreated(args: any) {
    this.sidebar.element.style.visibility = '';
  }
  btnClick() {
    if (this.togglebtn.element.classList.contains('e-active')) {
      //  this.togglebtn.content = 'Open';
      this.sidebar.hide();
    } else {
      //  this.togglebtn.content = 'Close';
      this.sidebar.show();
    }
  }
  closeClick() {
    this.sidebar.hide();
    this.togglebtn.element.classList.remove('e-active');
    // this.togglebtn.content = 'Open'
  }
  @Input() drawer: any;
  @Input() user!: any;
  idClinica!: string;
  isAdmin: boolean = false;

  @Input() collapsed: boolean = false;

  @Output() onCollapse = new EventEmitter<boolean>();

  mainMenu: any = [
    {
      Label: 'Home',
      Icon: 'home',
      RouterLink: '/home',
    },
  ];

  public cssClass: string = 'mytree';

  public data: MenuItem[] = [
    {
      label: 'Amministrazione',
      auth: true,
      items: [
        {
          label: 'Settings',
          url: '/settings',
        },
        {
          label: 'Journeys',
          url: '/journeys',
        },
        {
          label: 'Configurazione',
          url: '/genconfig',
        },
        {
          label: 'Profili',
          url: '/profiles',
        },
        {
          label: 'Squadre',
          url: '/teams',
        },
        {
          label: 'Domini',
          url: '/domains',
        },
        {
          label: 'Sedi',
          url: '/companies',
        },
        {
          label: 'Licenze',
          url: '/ad-license',
        },
        {
          label: 'Utenti',
          url: '/users',
        },
        {
          label: 'Convenzioni',
          url: '/conventions',
        },
        {
          label: 'Utenti esterni',
          url: '/users-external',
        },
        {
          label: 'Eventi',
          url: '/events',
        },
      ],
    },
    {
      label: 'Operazioni',
      auth: true,
      items: [
        {
          label: 'CRM',
          url: '/crm',
        },
        {
          label: 'Obiettivi',
          url: '/targets',
        },
        {
          label: 'Cruscotto',
          url: '/cockpit',
        },
        // {
        //   label: 'Soggetti',
        //   url: '/subjects'
        // },
        {
          label: 'Pratiche',
          url: '/orders',
        },
        {
          label: 'Planning',
          url: '/planner',
        },
        {
          label: 'Agenda',
          url: '/schedule',
        },
        {
          label: 'Consuntivi',
          url: '/final-balancer',
        },
        {
          label: 'Certificati',
          url: '/certificates',
        },
        {
          label: 'Sondaggi',
          url: '/surveysmenu',
        },
        {
          label: 'Licenze',
          url: '/op-license',
        },
        {
          label: 'La Mia Sede',
          url: '/my-branch',
        },
        {
          label: 'ConsuntivazioneMulti',
          url: '/final-balancer-multi',
        },
        {
          label: 'ConsuntiviDubai',
          url: '/final-balancer-d',
        },
        {
          label: 'AnomalieConsuntivazione',
          url: '/final-balancer/history',
        },
        {
          label: 'Approvazione Storni',
          url: '/cancellations-approval',
        },
        {
          label: 'DPI',
          url: '/dpi',
        },
        {
          label: 'Incassi Cessione',
          url: '/transfer-receipts',
        },
        {
          label: 'Approvazione Ordini',
          url: '/ordersendorsement',
        },
        {
          label: 'Verifica Check-in EAPP',
          url: '/checkin-verification',
        },
      ],
    },
    {
      label: 'Wiki',
      auth: false,
    },
    {
      label: 'FAQ',
      auth: false,
    },
  ];

  public dataOld: Object[] = [
    {
      nodeId: '02',
      nodeText: 'Operation',
      icon: 'operazioni',
      nodeChild: [
        {
          nodeId: '02-01',
          nodeText: 'Cruscotto',
          icon: 'cruscotto',
          url: '/cockpit',
        },
        // { nodeId: '02-02', nodeText: 'Soggetti', icon: 'soggetti', url: '/subject' },
        {
          nodeId: '02-03',
          nodeText: 'Pratiche',
          icon: 'pratiche',
          url: '/orders',
        },
        {
          nodeId: '02-04',
          nodeText: 'Planning',
          icon: 'planning',
          url: '/planner',
        },
        {
          nodeId: '02-05',
          nodeText: 'Agenda',
          icon: 'agenda',
          url: '/schedule',
        },
        {
          nodeId: '02-06',
          nodeText: 'Consuntivi',
          icon: 'consuntivi',
          url: '/final-balancer',
        },
        {
          nodeId: '02-07',
          nodeText: 'Licenze',
          icon: 'licenze',
          url: '/op-license',
        },
      ],
    },
    {
      nodeId: '03',
      nodeText: 'FAQ',
      icon: 'icon-help-circled icon',
    },
  ];
  public field: Object = {
    dataSource: this.data,
    id: 'nodeId',
    text: 'nodeText',
    child: 'nodeChild',
    iconCss: 'icon',
    url: 'url',
  };

  public loadRoutingContent(args: NodeSelectEventArgs): void {
    let data: any = this.tree.getTreeData(args.node);
    let routerLink: string = data[0].url;
    this.router.navigate([routerLink]);
  }

  token!: string;
  currentUser: any;

  constructor(
    public libService: LibService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    private sidebarService: SidebarService,
    public userSessionService: UserSessionService,
    private profileService: ProfileService,
    private controllerService: ControllerService,
    public translate: TranslateService,
    private angularFireMessaging: AngularFireMessaging,
    ) { }

  ngDoCheck() {}

  controllers$ = this.controllerService.getAllControllers();
  data$ = this.controllers$.pipe(
    map((controllers) => {
      //console.log('CONTROLLERS --> ', controllers);
      this.libService.lockPage('Load Actions');
      return controllers.map((controller) => {
        return {
          label: controller.name,
          auth: true,
          items: controller.ControllersActionsRel?.map((action) => {
            // console.log("actions --> ", action)
            this.libService.unlockPage();
            return {
              label: action.name,
              url: action.routeTo,
              order: action.order,
              icon: action.icon,
            };
          }).sort((a, b) => {
            if (a.order > b.order) {
              return 1;
            }
            if (a.order < b.order) {
              return -1;
            }
            return 0;
          }),
        };
      });
    })
  );
  /* userActions$ = this.getuserActions()
  data$ = this.userActions$.pipe(
    switchMap((userActions: any) => {
      return this.controllers$.pipe(
        map((controllers: any) => {
          return controllers.map((controller: any) => {
            return controller.ControllersActionsRel.filter((action:any) => userActions.contains(action.id))
          })
        })
      )
    })
  ) */

 async ngOnInit(): Promise<void> {

    if (Notification.permission === 'denied') {
      console.log('Problemi con le notifiche')
    } else {
      await this.requestPermission();
    }

    this.libService.lockPage(this.translate.instant('LOADINGMESSAGES.caricamentoVisibilita'));
    console.log('init sidebar is user %o', this.authService.user());
    this.controllers$.subscribe(res => {
      console.log('controllers: ', res);

    })
    if (this.isUser()) {
      if (!this.userSessionService.getState('user')) {
        this.loaduserActions();
      } else {
        //this.userActions = this.mergeProfilesActions(this.userSessionService.getState('user'));
        let actions: Action[] = [];
        //console.log('user %o user.UsersProfilesRel %o',user,user.UsersProfilesRel);
        this.userSessionService
          .getState('user')
          .UsersProfilesRel!.forEach((profile: any) => {
            //call profile
            this.profileService.getProfile(profile.id).subscribe((pr) => {
              let aa = actions.concat(pr.ProfilesActionsRel);

              actions = Array.from(new Set(aa));
              //console.log('----------------------------------- aa %o actions %o', aa, actions);
              this.userActions = actions;
            });
          });
        //console.log('SET UNO userations %o', this.userActions);
      }
    }
  }

  mergeProfilesActions(user: User): Action[] {
    let actions: Action[] = [];
    //console.log('user %o user.UsersProfilesRel %o',user,user.UsersProfilesRel);
    user.UsersProfilesRel!.forEach((profile) => {
      //call profile
      this.profileService.getProfile(profile.id).subscribe((pr) => {
        //console.log('----------------------------------- pr %o', pr);
        let aa = actions.concat(pr.ProfilesActionsRel);

        actions = Array.from(new Set(aa));
        //console.log('----------------------------------- aa %o actions %o', aa, actions);
      });
      return actions;
    });
    return actions;
  }

  hasNode(nodeName: string) {
    const actions = this.userActions;
    //console.log('----------------------------------- userActions %o', actions);
    if (!(actions == null) && actions.length > 0) {
      for (let action of actions) {
        // console.log('--------------------------------- found node in userActions with name:%o action %o', nodeName, action);
        if (!(action == null) && nodeName === action.name) return true;
      }
    }
    return false;
  }

  getTooltip(label: any) {
    return this.translate.instant('SIDEBAR.' + label);
  }

  hasNodes(nodes: any) {
    const actions = this.userActions;
    if (
      !(actions == null) &&
      actions.length > 0 &&
      !(nodes.items == null) &&
      nodes.items.length > 0
    ) {
      for (let itemN of nodes.items) {
        for (let action of actions) {
          if (itemN.label == action.name) return true;
        }
      }
    }
    return false;
  }

  loaduserActions(): void {
    //console.log('LOAD USER ACTIONS');
    this.libService.lockPage('');
    this.usrs = this.userSessionService.getUser(this.authService.user().email);

    // Setta le actions per le visibilità
    this.userSessionService.getVisibilities().subscribe(
      (v) => {
        console.log(v);
      },
      (err) => {
        console.log(err);
      }
    );

    this.usrs.subscribe(
      (u) => {
        this.libService.unlockPage();
        //console.log('get users %o', u);
        this.userSessionService.saveState('user', u[0]);
        //console.log('SAVE STATE  %o', u[0]);
        // this.userActions = this.mergeProfilesActions(u[0]);

        if (!(this.userSessionService == null)) {
          let currentLanguage = this.userSessionService.getCurrentLanguage();
          this.translate.use(currentLanguage);
        }

        let actions: Action[] = [];
        //console.log('user %o user.UsersProfilesRel %o',user,user.UsersProfilesRel);
        this.userSessionService
          .getState('user')
          .UsersProfilesRel!.forEach((profile: any) => {
            //call profile
            this.profileService.getProfile(profile.id).subscribe((pr) => {
              let aa = actions.concat(pr.ProfilesActionsRel);

              actions = Array.from(new Set(aa));
              //console.log('----------------------------------- aa %o actions %o', aa, actions);
              this.userActions = actions;
            });
          });

        //console.log('SET DUE userations %o', this.userActions);
      },
      (err) => {
        this.libService.unlockPage();
        this.toastr.error(
          this.translate.instant('TOASTR.MESSAGE_5'),
          this.translate.instant('TOASTR.WARNING')
        );
      }
    );
  }

  logout() {
    this.authService.logout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setCollapsed(event?.target?.innerWidth <= 1024);
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.sidebarService.setCollapsed(value);
  }

  drawerToggle = () => {
    this.drawer.toggle();
  };

  isLogged() {
    return this.authService.user();
  }

  getIconName(label: string) {
    return label.toLowerCase().replaceAll(' ', '_');
  }

  goTo(url: any) {
    if (window.innerWidth <= 1024) this.setCollapsed(true);
    this.router.navigate([url]);
  }

  goToExt(url: any) {
    window.open(url, '_blank');
  }

  goHome() {
    this.router.navigate(['/']);
  }

  isUser() {
    return this.authService.user();
  }

  signOut() {
    this.authService.logout();
    let urlLogout =
      environment.cognitoEntryPoint +
      '/logout?client_id=' +
      environment.cognitoAppClientId +
      '&redirect_uri=' +
      environment.postLogoutRedirectUri;
    console.log('LOGOUT sidebar on %o', urlLogout);
    window.location.href = urlLogout;
  }

  signIn() {
    this.authService.login();
  }

  inPortrait() {
    return window.innerWidth <= 1024;
  }

  async requestPermission() {

    this.userSessionService.getVisibilities().subscribe(
      v => {
        navigator.serviceWorker.ready.then((reg) => {
          reg.pushManager.getSubscription().then(
            (subscription) => {
              const options = subscription?.options;
              console.log(options?.applicationServerKey);
            },
            (err) => console.log(err)
          );
        });
    
        this.angularFireMessaging.requestToken.subscribe(
          (token) => {
            console.log(this.authService.user())
            if (token) {
              this.token = token;
              const url = `${environment.rootApiFinance}/V1/Input/SetUserToken?account=${v.email}`;
              const data = {
                id: '',
                userId: v.id.toString(),
                deviceToken: this.token,
                deviceOS: navigator.userAgent,
                status: '1',
              };
    /*
              fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              })
                .then((response) => response.json())
                .then((data) => {
                  console.log('Success:', data);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
                */
            }
          },
          (err) => {
            console.error('Unable to get permission to notify.', err);
          }
        );
      },
      err => {
        console.log(err)
      }
    );


   
  }

}
