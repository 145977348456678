import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor(
    public libService: LibService,
    private http: HttpClient
  ) { }

  private apiUrl = `${environment.rootApiCrm}v1.0/channels`;

  /**
   * Get all channels, optionally filtered by companyCode, countryCode, or companyId.
   * @param companyCode Optional company code for filtering.
   * @param countryCode Optional country code for filtering.
   * @param companyId Optional company ID for filtering.
   * @returns Observable of the list of channels.
   */
  getAllChannels(companyCode?: string, countryCode?: string, companyId?: number): Observable<any> {
    let params = new HttpParams();
    if (companyCode) {
      params = params.append('companyCode', companyCode);
    }
    if (countryCode) {
      params = params.append('countryCode', countryCode);
    }
    if (companyId) {
      params = params.append('companyId', companyId.toString());
    }

    return this.http.get<any>(this.apiUrl, { params });
  }
}
