<h1 mat-dialog-title>Dettagli DPI</h1>
<div mat-dialog-content>
  <p><strong>ID Header:</strong> {{ data.headerId }}</p>
  <p><strong>Operatore:</strong> {{ data.userName }}</p>

  <table mat-table [dataSource]="data.rows" style="width: 100%;">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="dpiName">
      <th mat-header-cell *matHeaderCellDef>Nome DPI</th>
      <td mat-cell *matCellDef="let row">{{ row.dpiName }}</td>
    </ng-container>

    <ng-container matColumnDef="categoryDPI">
      <th mat-header-cell *matHeaderCellDef>Categoria</th>
      <td mat-cell *matCellDef="let row">{{ row.categoryDPI }}</td>
    </ng-container>

    <ng-container matColumnDef="declaredQuantity">
      <th mat-header-cell *matHeaderCellDef>Quantità Dichiarata</th>
      <td mat-cell *matCellDef="let row">{{ row.declaredQuantity }}</td>
    </ng-container>

    <ng-container matColumnDef="minimumQuantity">
      <th mat-header-cell *matHeaderCellDef>Quantità Minima</th>
      <td mat-cell *matCellDef="let row">{{ row.minimumQuantity }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['id', 'dpiName', 'categoryDPI', 'declaredQuantity', 'minimumQuantity']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['id', 'dpiName', 'categoryDPI', 'declaredQuantity', 'minimumQuantity']"></tr>
  </table>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">Chiudi</button>
</div>
