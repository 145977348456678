<br>
<button (click)="downloadExport()" class="btn btn-lg btn-primary">
    <span class="indicator-label text-dark">Scarica Lista Partecipanti</span>
</button>
<br>
<div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
        <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
            [placeholder]="translate.instant('LABEL.SEARCH')" />
    </div>
</div>
<br>
<app-data-table [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
    [actions]="actions" sortActive=""></app-data-table>