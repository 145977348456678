import { StatusService } from 'src/app/services/status.service';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from './../../auth/auth.service';
import { DatePipe, Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, map, of, Subject, switchMap, tap } from 'rxjs';
import { ActivitiesService } from 'src/app/services/activities.service';
import { ActivityTypeReasonsService } from 'src/app/services/activity-type-reasons.service';
import { ActivityTypesService } from 'src/app/services/activity-types.service';
import { BranchService } from 'src/app/services/branch.service';
import { CategoryService } from 'src/app/services/category.service';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { RequestService } from 'src/app/services/request.service';
import { SubjectService } from 'src/app/services/subject.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { atLeastOne } from 'src/app/validators/activity.validator';
import { WikiService } from 'src/app/services/wikiservice.service';
import { StatusActivitiesService } from 'src/app/services/status-activities.service';
import { OrderService } from 'src/app/services/order.service';
import { CompanyService } from 'src/app/services/companyService';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-activities-detail',
  templateUrl: './activities-detail.component.html',
  styleUrls: ['./activities-detail.component.scss']

})
export class ActivitiesDetailComponent implements OnInit {

  formActivities: UntypedFormGroup;
  formSubjects: UntypedFormGroup;
  categorySingle: any;
  controlName = 'subjectId';
  connectedSubject: any;
  connectedRequest: any;
  external: any;
  activity: any;
  isNew: boolean;
  language = (window.navigator.language.split("-", 1))[0].toUpperCase();
  searchType: any;
  catSelected: any;
  branches: any = [];
  categories: any;
  allCategories: any = [];
  types: any;
  reasons: any;
  order: any;
  statusPotenziale: any;
  subject = this.activitiesService.getSubjectData();
  hideSubject: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  statusActivities: any;
  service: any;
  disabled: boolean;

  statusActivities$ = this.statusActivityService.getAllActivityStatus();

  get readonly() {
    if (Object.keys(this.subject).length !== 0) {
      return true;
    } else {
      return false;
    }
  }

  branches$ = this.branchService.getBranchesByLangCode(this.language);
  // category$ = this.categoryService.getCategoriesByLangCode(this.language);
  activityTypes$ = this.activityTypeService.getAllActivityTypes();
  activityReasons$ = this.activityReasonService.getAllActivityTypeReasons();
  id$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    tap(id => {
      parseInt(id!) > 0 ? this.isNew = false : this.isNew = true;
    })
  )
  activity$ = this.id$.pipe(
    switchMap(id => {
      // this.activitiesService.getActivityById(parseInt(id!));
      return id !== '0' ? this.activitiesService.getActivityById(parseInt(id!)) : of(null);
    })
  )
  requests$ = this.requestService.getAllRequestsByAssignedOperator(this.authService.user().name);

  status$ = this.statusService.getById(7, "Subject");

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public navService: NavService,
    public translate: TranslatorService,
    public subjectService: SubjectService,
    public activitiesService: ActivitiesService,
    private toastr: ToastrService,
    private libService: LibService,
    public requestService: RequestService,
    public statusActivityService: StatusActivitiesService,
    public datepipe: DatePipe,
    public branchService: BranchService,
    public categoryService: CategoryService,
    private activityTypeService: ActivityTypesService,
    private activityReasonService: ActivityTypeReasonsService,
    public userSession: UserSessionService,
    public authService: AuthService,
    public router: Router,
    public location: Location,
    private changeDetectorRef: ChangeDetectorRef,
    public wikiService: WikiService,
    private statusService: StatusService,
    public orderService: OrderService,
    public companyService: CompanyService,
    public countryService: CountryService,
  ) {
    // this.formSubjects = this.fb.group({
    //   subject: new FormControl({})
    // },



    this.formActivities = this.fb.group({
      id: [null],
      owner: [null, Validators.required],
      type: [null, Validators.required],
      reason: [null],
      result: [null],
      subject: [null, Validators.required],
      category: [null, Validators.required],
      activityDate: [null],
      activityStatus: null,
      branch: [null, Validators.required],
      orderCode: [null],
      notes: [null]
    })
  }

  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  goToWikiByKey(k: any) {
    window.open(this.wikiService.getWikiUrl(k));
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log("SUBJECT --> ", this.subject);
    if (Object.keys(this.subject).length !== 0) {
      this.formActivities.patchValue({
        subject: this.subject
      }, { emitEvent: true });
      this.connectedSubject = this.subject;
      this.activitiesService.setSubjectData({});
      this.setSubject(this.subject)
      this.changeDetectorRef.detectChanges();
    }
  }

  addCategories(val: any) {
    let branch = this.branches.find((element: any) => element.businessLineCode == val);
    this.categories = branch?.categories
  }

  ngOnInit(): void {


    this.formActivities.get('branch')?.valueChanges.subscribe(val => {
      this.addCategories(val)
    });

    // console.log("SOGGETTO CHE ARRIVA DAL SERVIZIO FRONT END --> ", this.subject);
    let now: any = new Date();
    this.initNavbar();
    this.libService.lockPage('');
    this.route.queryParamMap.subscribe(params => {
      const subjectId = params.get('subjectId');
      if (subjectId) {
        // Recupera i dati del soggetto e applica la stessa logica
        this.subjectService.getSubject(subjectId).subscribe({
          next: (subject) => {
            if (subject) {
              console.log('Soggetto trovato:', subject);
              // Precompila il form con i dati del soggetto
              this.formActivities.patchValue({
                subject: subject
              }, { emitEvent: true });
              this.connectedSubject = subject;

              // Aggiorna altre logiche legate al soggetto
              this.setSubject(subject);
              this.changeDetectorRef.detectChanges();

              // Reset dei dati nel servizio se necessario
              this.activitiesService.setSubjectData({});
            }
          },
          error: (error) => {
            console.error('Errore durante il recupero del soggetto da cruscotto:', error);
          }
        });
      }
    });
    combineLatest([this.activityTypes$, this.activityReasons$, this.activity$, this.status$, this.statusActivities$]).subscribe({
      next: ([types, reasons, act, status, statusActivities]) => {
        //this.branches = branches.sort((a : any , b: any) => this.translate.instant('ACTIVITIES.' + a.code!) <= this.translate.instant('ACTIVITIES.' + b.code!) ? -1 : 1);
        this.types = types.sort((a: any, b: any) => this.translate.instant('ACTIVITIES.' + a.uiLabel!) <= this.translate.instant('ACTIVITIES.' + b.uiLabel!) ? -1 : 1);
        this.reasons = reasons.sort((a: any, b: any) => this.translate.instant('ACTIVITIES.' + a.uiLabel!) <= this.translate.instant('ACTIVITIES.' + b.uiLabel!) ? -1 : 1);
        this.activity = act;
        this.statusPotenziale = status;
        this.statusActivities = statusActivities;

        console.log("ACTIVITY -->", this.activity)
        if (this.isNew) {
          this.formActivities.patchValue({ activityStatus: this.statusActivities.find((status: any) => status.code === 'FATTO') })
          this.searchType === null || this.searchType === undefined ?
            this.disabled = true : this.disabled = false;
        }

        if (this.isNew == false) {
          this.activity.activityDate = this.datepipe.transform(this.activity.activityDate, 'yyyy-MM-dd');
          this.connectedSubject = this.activity.subject;
          let patchStatus = this.statusActivities.find((stat: any) => stat.id === this.activity.activityStatus?.id);

          if (this.activity?.subject !== null && this.activity?.subject !== undefined) {
            this.searchType = 'Soggetto';
            this.service = this.subjectService;
          } else {
            this.searchType = 'Pratica'
            this.service = this.orderService;
          }

          if (this.activity.subject) this.setSubject(this.activity.subject)

          this.formActivities.patchValue({
            id: this.activity.id,
            owner: this.activity.owner,
            type: this.activity.type,
            reason: this.activity.reason,
            result: this.activity?.result,
            subject: this.activity.subject,
            category: this.activity.category,
            activityDate: this.activity.activityDate,
            activityStatus: this.statusActivities.find((status: any) => status.code === patchStatus?.code),
            branch: this.activity.branch,
            orderCode: this.activity.orderCode,
            notes: this.activity.notes
          });

        }
        this.libService.unlockPage();
      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })

    if (this.isNew == false) {
    } else {
      now = this.datepipe.transform(now, 'yyyy-MM-dd');
      this.formActivities.patchValue({
        owner: this.authService.user().name,
        activityDate: now
      })
    }
  }

  update() {

    if (this.formActivities.valid) { //&& this.formSubjects.valid
      // Controlla se stai creando una nuova attività con subjectId nell'URL
      const isNewWithSubjectId = this.isNew && this.route.snapshot.queryParamMap.has('subjectId');
      if (this.isNew == false) {
        this.activity = this.formActivities.getRawValue();
        // this.activity.category = this.getSelectedCategory();
        this.activity.subject = this.connectedSubject;

        console.log("ATTIVITA COMPLETA DA SALVARE --> ", this.activity);
        this.libService.lockPage('');
        this.activitiesService.updateActivity(this.activity).subscribe(val => {
          this.location.back();
          this.libService.unlockPage();
          this.toastr.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        });
      } else {

        this.activity = this.formActivities.getRawValue();
        // this.activity.category = this.getSelectedCategory();
        // console.log("ATTIVITA COMPLETA DA CREARE --> ", this.activity);
        this.connectedSubject !== null && this.connectedSubject !== undefined ?
          (this.activity.status = this.connectedSubject.status ? this.connectedSubject.status : this.statusPotenziale) : undefined;
        this.libService.lockPage('');
        this.activitiesService.createActivity(this.activity).subscribe(res => {
          this.libService.unlockPage();
          console.log("ATTIVITA CREATA --> ", res);
          // Esegui il redirect alla pagina activities se la condizione è soddisfatta
          if (isNewWithSubjectId) {
            this.router.navigate(['crm', { tabName: 'activities' }]);
          } else {
            this.location.back();
          }
          this.toastr.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        })
      }
    } else {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE_1'), this.translate.instant('TOASTR.WARNING'));
    }
  }

  initNavbar() {
    setTimeout(() => {
      let actions: any;
      // console.log("CRONOLOGIA --> ", this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString());
      actions = [
        { name: 'back', url: 'crm', params: { tabName: 'activities' } }
      ];

      let title = this.formActivities.get('id')?.value !== null ?
        this.translate.instant('LABEL.EDIT', { field: 'Attività' }) :
        this.translate.instant('LABEL.NEW', { field: 'Attività' })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }

  setSubject(e: any) {
    console.log(e)
    if (e.name) {

      this.companyService.getCompanyByCode(e.company).subscribe(
        (com) => {
         // console.log(com)
          this.countryService.getBusinessLinesAndCategories(com.country).subscribe((response) => {
           // console.log(response)
            this.branches = response

            let currentBranchValue = this.formActivities?.get('branch')?.value
            if (currentBranchValue) {
              this.addCategories(currentBranchValue)
            }

          });
        }
      );



      this.connectedSubject = e;
      this.formActivities.patchValue({
        subject: this.connectedSubject,
        orderCode: null
      })
    } else if (e.workType) {
      this.connectedRequest = e;
      this.formSubjects.patchValue({
        request: this.connectedRequest,
        orderCode: null
      })
    }
  }

  setFormValue(e: any) {
    this.formActivities = e;
  }


  getSelectedCategory(): any {
    let newValue: any = '';
    if (this.activity.category !== null && this.activity.category !== undefined) {
      // this.categories.forEach((element: any) => {
      //   this.activity.category == element.name? newValue = element.code: undefined;
      // });
      let provv = this.categories.find((cat: any) => cat.code === this.activity.category);
      newValue = provv.code;
    }
    return newValue;
  }

  // onSearchTypeChange(e:any) {
  //   switch (e) {
  //     case 'Soggetto':
  //       this.disabled = false; this.hideSubject = false;
  //       this.service = this.subjectService;
  //       this.emitEventToFTSearch()
  //       break;

  //     case 'Pratica':
  //       this.disabled = false; this.hideSubject = true;
  //       this.service = this.orderService;
  //       this.emitEventToFTSearch();
  //       break;

  //     default:
  //       break;
  //   }
  // }

  emitEventToFTSearch() {
    console.log("EVENTO INNESTATO");
    this.eventsSubject.next();
  }

  setOrder(e: any) {
    console.log("EVENTO SELEZIONE PRATICA --> ", e);
    this.formActivities.patchValue({
      subject: null,
      orderCode: e.code
    })
  }
}
