export const environment = {
  production: false,
  cognitoUserPoolId: 'eu-west-1_vq0waCEDJ',
  cognitoAppClientId: '7btp64tds3cvn5d07gtn643g7v',
  cognitoEntryPoint: 'https://edacopenid.auth.eu-west-1.amazoncognito.com',
  rootApiFoundation: 'https://albertfoundation.dune.it/',
  //redirectUri: 'http://localhost:4200',
  redirectUri: 'https://albert.dune.it',
  //rootApiFoundation: "http://localhost:80/",
  //rootApiLog: "",
  rootApiLog: 'https://albertlog.dune.it/',
  //rootApiCrm: "http://localhost:80/",
  rootApiCrm: 'https://albertcrm.dune.it/',
  //rootApiCrm: "http://localhost:80/",
  rootApiCrmLocal: 'https://albertcrm.dune.it/',
  rootAlbertJob: 'http://albertjobslb-1923462835.eu-west-1.elb.amazonaws.com/',
  //rootApiCrmLocal: "http://localhost:80/",
  //rootApiPaperwork: "http://localhost:80/",
  rootApiPaperwork: 'https://albertpaperwork.dune.it/',
  //redirectUri:"https://my-qs-app.net:4200",
  //postLogoutRedirectUri: 'http://localhost:4200',
  postLogoutRedirectUri: 'https://albert.dune.it',
  //rootApiFoundation: "http://localhost:80/",
  //rootInvoiceCertificates:"https://delta3finance.azurewebsites.net",
  rootInvoiceCertificates:
    'https://albertfinanceapp-dev.azurewebsites.net/Certificati/GestioneCertificati?token=',
  rootInvoiceCertificatesPractice:
    'https://albertfinanceapp-dev.azurewebsites.net/Certificati/DeliberaCommessa?token=',
  rootInvoiceCrediti:
    'https://albertfinanceapp-dev.azurewebsites.net/Crediti/GestioneScadenze?token=',
  rootInvoiceFranchising:
    'https://albertfinanceapp-dev.azurewebsites.net/Ordini/GestioneOrdini?token=',
  lambdaBaseUrl: 'https://hfmgmfswpj.execute-api.eu-west-1.amazonaws.com/',
  googleApiKey: 'AIzaSyAFVlDH8qnrmcQTi4nnteLnxStc12Te3I4',
  rootNews: 'https://hrportal.ediliziacrobatica.com/web-service/',
  rootToken: 'dunegroup:iAFGl0ysA71pz&KF',
  elecSign: 'https://api-sandbox.yousign.app/v3/',
  elecApiKey: '6xPqmhXcmH7cJTfkt8huAf8n9v7MWoFD',
  rootApiInvoices:
    'https://albertfinance-dev.azurewebsites.net/V1/output/GetFattureUtente',
  rootApiCollections:
    'https://albertfinance-dev.azurewebsites.net/V1/output/GetIncassiUtente',
  baseUrlWiki: 'http://52.212.224.107:8080/xwiki/',
  KENDO_UI_LICENSE:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk0NTc5NTk0fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTY5NDU3OTU5NH0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk0NTc5NTk0fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk0NTc5NTk0fV0sImludGVncml0eSI6ImtkbFlwQlk5NXA4U3Jyc05MUDB5YnBCbDRvTT0iLCJsaWNlbnNlSG9sZGVyIjoiYW5kcmVhLnJvZ2dlc0BkdW5lZ3JvdXAuaXQiLCJpYXQiOjE2NjMwOTgwMDMsImF1ZCI6ImFuZHJlYS5yb2dnZXNAZHVuZWdyb3VwLml0IiwidXNlcklkIjoiYjJiMzJkNjYtNTg0OC00NDlmLWE2MjAtN2VmOWY3MzI3MjYwIn0.Q1SlyFLYRQZG7jq4LCYMS8yOQBeEFM0lAuzkdlXDaOYut6IuvUw0heWIfOgwOvNsyxnZmwAHLrR2vH4RG2dFq6hKNaZf20sfSa9JCz77XDIfGytEAm_6gMmFsxVMldjc58E4dQEud3qALQPblpq8OZhoynsGwJTXdqaMZ0kvl57-bDL1i7_g-F2jrnYl-ITAnqwl4G8XeLqlTojannU_7TPbiepLy0YeeBtrrU1cFPvN6yDFUizemwB4GBdTOiqYP1D4gwRNxncDgoy73UGHpk9myO-A2SdzzdugJEuNbf2w4SosoAAlirvKCZg7KbpfJ0IzhDlxEHh-zrM-UWKWag',
  baseUrlERP:
    'https://fa-esbu-test-saasfaprod1.fa.ocs.oraclecloud.com/fscmUI/faces/FuseWelcome?_afrLoop=57289177310081898&_afrWindowMode=0&_afrWindowId=null&_adf.ctrl-state=917cqhhax_1&_afrFS=16&_afrMT=screen&_afrMFW=1280&_afrMFH=569&_afrMFDW=1280&_afrMFDH=720&_afrMFC=8&_afrMFCI=0&_afrMFM=0&_afrMFR=144&_afrMFG=0&_afrMFS=0&_afrMFO=0',
  baseUrlTicket: 'https://support.ediliziacrobatica.com/HEAT',
  checkDelibera: 'https://albertfinance-dev.azurewebsites.net/v1/Input/',
  getDelibera: 'https://albertfinance-dev.azurewebsites.net/v1/Output/',
  callDocCompPrev: 'https://albertdocumentcomposition-dev.azurewebsites.net/mcom4',
  rootSurveys:
    'https://albertquality.azurewebsites.net/Sondaggi/GestioneSondaggi?token=',
  rootOrdersEndorsement:
    'https://albertfinanceapp-dev.azurewebsites.net/Ordini/ApprovazioneOrdini?token=',
  rootActiveInvoicing:
    'https://albertfinanceapp-dev.azurewebsites.net/FatturazioneAttiva/GestioneFatturazioneAttiva?token=',
  rootDebtCollection:
    'https://albertfinanceapp-dev.azurewebsites.net/RecuperoCrediti/GestioneScaduto?token=',
  rootPluginFinance: 'https://deltaes.ediliziacrobatica.com/albe/edx2apro.html',
  rootPluginProduction:
    'https://deltaes.ediliziacrobatica.com/albe/edx2albe.html',
  rootPluginAcquisti:
    'https://deltaes.ediliziacrobatica.com/albe/edx2alba.html',
  rootApiFinance: 'https://albertfinance-dev.azurewebsites.net',
  sessionToken: '8F4CE4B2A6679BE31D911F6A9D4DA',
  rootMyHeadquarters:
    'https://albertfinanceapp-dev.azurewebsites.net/CruscottoCrm/Cruscotto?token=',
  rootFinalBalancerMulti:
    'https://albertfinanceapp-dev.azurewebsites.net/Consuntivazione/Consuntivazione?token=',
  rootCancellationsApproval:
    'https://albertfinanceapp-dev.azurewebsites.net/Certificati/ApprovazioneStorni?token=',
  rootDpi:
    'https://albertfinanceapp-dev.azurewebsites.net/Dpi/GestioneDpi?token=',
  rootTransferReceipts:
    'https://albertfinanceapp-dev.azurewebsites.net/Incassi/GestioneIncassi?token=',

    firebaseConfig: {
      apiKey: "AIzaSyAFVlDH8qnrmcQTi4nnteLnxStc12Te3I4",
      authDomain: "aapp-3e0d8.firebaseapp.com",
      projectId: "aapp-3e0d8",
      storageBucket: "aapp-3e0d8.appspot.com",
      messagingSenderId: "417315253735",
      appId: "1:417315253735:web:4ba5c29c33fec0bbe3a142",
      measurementId: "G-6E396YBQQL"    
    },
    rootAlbertcruscotto: 'https://albertcruscotto-dev.azurewebsites.net/',
    rootApiChatbot: 'https://genaiservices-be.datapartners.ch/',
};
/*
export const environment = {
  production: true,
  cognitoUserPoolId: "eu-west-1_vq0waCEDJ",
  cognitoAppClientId: '7btp64tds3cvn5d07gtn643g7v',
  cognitoEntryPoint:"https://edacopenid.auth.eu-west-1.amazoncognito.com",
  rootApiFoundation: "https://albertfoundation.acrobatica.ai/",
  rootApiLog: "https://albertlog.acrobatica.ai/",
  rootApiCrm: "https://albertcrm.acrobatica.ai/",
  rootApiCrmLocal:"https://albertcrm.acrobatica.ai/",
  rootAlbertJob:"https://albertjob.acrobatica.ai/",
 // redirectUri:"https://albert.acrobatica.ai",
 redirectUri: 'http://localhost:4200',
 // rootApiPaperwork: "https://albertpaperwork.acrobatica.ai/",
  rootApiPaperwork: "http://localhost:80/",
  postLogoutRedirectUri:"https://albert.acrobatica.ai",
  rootInvoiceCertificates:"https://albertfinanceapp.azurewebsites.net/Certificati/GestioneCertificati?token=",//https://albertfinanceapp.azurewebsites.net/Certificati/GestioneCertificati",
  rootInvoiceCertificatesPractice:"https://albertfinanceapp.azurewebsites.net/Certificati/DeliberaCommessa?token=",// "https://albertfinanceapp.azurewebsites.net/Certificati/DeliberaCommessa",
  rootInvoiceFranchising:"https://albertfinanceapp.azurewebsites.net/Ordini/GestioneOrdini?token=",
  rootInvoiceCrediti: "https://albertfinanceapp.azurewebsites.net/Crediti/GestioneScadenze?token=",
  lambdaBaseUrl: "https://hfmgmfswpj.execute-api.eu-west-1.amazonaws.com/",
  googleApiKey:"AIzaSyBMKHOxlZc_oirDPSyqQj150G1m5Sy3DDQ",
  rootNews:"https://hrportal.ediliziacrobatica.com/web-service/",
  rootToken:"dunegroup:iAFGI0ysA71pz&KF",
  elecSign:"https://api.yousign.app/v3",
  elecApiKey:"6xPqmhXcmH7cJTfkt8huAf8n9v7MWoFD",
  rootApiInvoices:"https://albertfinance.azurewebsites.net/V1/output/GetFattureUtente",
  rootApiCollections:"https://albertfinance.azurewebsites.net/V1/output/GetIncassiUtente",
  baseUrlWiki:"http://52.212.224.107:8080/xwiki/",
  KENDO_UI_LICENSE: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk0NTc5NTk0fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTY5NDU3OTU5NH0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk0NTc5NTk0fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk0NTc5NTk0fV0sImludGVncml0eSI6ImtkbFlwQlk5NXA4U3Jyc05MUDB5YnBCbDRvTT0iLCJsaWNlbnNlSG9sZGVyIjoiYW5kcmVhLnJvZ2dlc0BkdW5lZ3JvdXAuaXQiLCJpYXQiOjE2NjMwOTgwMDMsImF1ZCI6ImFuZHJlYS5yb2dnZXNAZHVuZWdyb3VwLml0IiwidXNlcklkIjoiYjJiMzJkNjYtNTg0OC00NDlmLWE2MjAtN2VmOWY3MzI3MjYwIn0.Q1SlyFLYRQZG7jq4LCYMS8yOQBeEFM0lAuzkdlXDaOYut6IuvUw0heWIfOgwOvNsyxnZmwAHLrR2vH4RG2dFq6hKNaZf20sfSa9JCz77XDIfGytEAm_6gMmFsxVMldjc58E4dQEud3qALQPblpq8OZhoynsGwJTXdqaMZ0kvl57-bDL1i7_g-F2jrnYl-ITAnqwl4G8XeLqlTojannU_7TPbiepLy0YeeBtrrU1cFPvN6yDFUizemwB4GBdTOiqYP1D4gwRNxncDgoy73UGHpk9myO-A2SdzzdugJEuNbf2w4SosoAAlirvKCZg7KbpfJ0IzhDlxEHh-zrM-UWKWag",
  baseUrlERP:"https://login-esbu-saasfaprod1.fa.ocs.oraclecloud.com/oam/server/obrareq.cgi?ECID-Context=1.005wVjXgJvK0rm5LvQH7iX0000EI0000B6%3BkXjE",
  baseUrlTicket:"https://support.ediliziacrobatica.com/HEAT",
  checkDelibera:"https://albertfinance.azurewebsites.net/v1/Input/",
  getDelibera:"https://albertfinance.azurewebsites.net/v1/Output/",
  callDocCompPrev:"https://albertdocumentcomposition-dev.azurewebsites.net/mcom4"
};
*/
