import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, map, tap, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Company } from 'src/app/models/company';
import { Status } from 'src/app/models/status';
import { SubjectType } from 'src/app/models/subjectType';
import { User } from 'src/app/models/user';
import { WorkType } from 'src/app/models/workType';
import { BuildingService } from 'src/app/services/building.service';
import { ChannelService } from 'src/app/services/channel.service';
import { CompanyService } from 'src/app/services/companyService';
import { FTIndexAllService } from 'src/app/services/ftindex-all.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { WorkTypeService } from 'src/app/services/worktype.service';

@Component({
  selector: 'app-preventive-request',
  templateUrl: './preventive-request.component.html',
  styleUrls: ['./preventive-request.component.scss']
})
export class PreventiveRequestComponent implements OnInit, OnDestroy {

  @Input()form: UntypedFormGroup;
  @Input()types: any
  @Input() origins: any;
  @Input() edit: boolean;
  @Input() isNew: boolean;


  statusTemporary: Status
  controlTypes = ['Privato', 'Amministratore'];
  hideLegalEntity: boolean = true;
  workTypes: any;
  wt: any;

  contactChannels$: any;


  companies: Company[]

  companies$ = this.companyService.getAllCompaniesForRequest().pipe(
    map((companies: any[]) => {
      return companies.sort((a, b) => a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      this.companies = [...new Set(companies)]
      this.companies.push({id: 0, code: undefined, name: ''})
    })
  );

  workTypes$ = this.workTypeService.getAllWorkTypes()//.pipe(
    // map(workTypes => {
    //   return workTypes.filter(workType => workType.codLanguage?.toLocaleLowerCase() === this.form.get('building.company')?.value)//this.translate.currentLang
    // })
  //)

  constructor(
    public subjectService: SubjectService,
    public buildingService: BuildingService,
    private companyService: CompanyService,
    private workTypeService: WorkTypeService,
    private statusService: StatusService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private channelService: ChannelService
  )
  {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.types !== null && this.types !== undefined)
      this.types.sort((a : any , b: any) => a.description!.toUpperCase() <= b.description!.toUpperCase() ? -1 : 1)
    if (this.origins !== null && this.origins !== undefined)
      this.origins.sort((a : any , b: any) => a.origin!.toUpperCase() <= b.origin!.toUpperCase() ? -1 : 1)
    console.log("WORKTYPES --> ", this.wt);

  }
  ngOnInit(): void {

    forkJoin([this.companies$, this.workTypes$]).subscribe({
      next: ([companies, workTypes]: any) => {
        this.workTypes = workTypes;
        if (this.workTypes !== null && this.workTypes !== undefined)
          this.workTypes.sort((a : any , b: any) => a.workType!.toUpperCase() <= b.workType!.toUpperCase() ? -1 : 1)

        if (this.form.get('building.company')?.value !== undefined && this.form.get('building.company')?.value !== null) {
          let currCompany = this.companies?.find(company => company.code === this.form.get('building.company')?.value);
          const wt = this.workTypes?.filter((workType: any) => {
            return workType.codCountry == currCompany?.country
          })
          console.log("TIPI DI LAVORAZIONE --> ", wt)
          this.wt = wt;

        }
      },
    })

    this.form.get('building.company')?.valueChanges.subscribe(val => {
      let currCompany = this.companies?.find(company => company.code === val);
      const wt = this.workTypes?.filter((workType: any) => {
        return workType.codCountry == currCompany?.country
      }).sort((a : any , b: any) => a.workType <= b.workType ? -1 : 1)
      console.log("TIPI DI LAVORAZIONE --> ", wt)
      this.wt = wt;
    })
    //set status provvisorio per subject e building
    this.statusService.getById(1, 'Subject').subscribe(res => {
      this.statusTemporary = res
      this.form.get('subject')?.get('status')?.setValue(this.statusTemporary)
      this.form.get('building')?.get('status')?.setValue(this.statusTemporary)

    })

    this.form.get('company')?.valueChanges.subscribe(company => {
      this.setCompanyLocationAndResponsibles(company)
    })
    this.contactChannels$ = this.channelService.getAllChannels(this.form.get('company')?.value);


    this.form.get('subject.type')?.valueChanges.subscribe(subject => {
      console.log("REQUEST SUBJECT --> ", subject);
      // console.log("COMPANY SUBJECT-->", this.form.get('subject.company')?.value)
      (this.controlTypes.includes(subject))? this.hideLegalEntity = true: this.hideLegalEntity = false;

      this.form.updateValueAndValidity();
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy(): void {
      this.form.get('subject')?.reset()
      this.form.get('building')?.reset()
  }

  selSubject(event: any){
    console.log('Selected: ', event);

    switch (event.type) {
      case 'Edificio':
        this.clearForm('building')
        this.form.get('building')?.patchValue(event)
        break;
      default:
        this.clearForm('subject')
        this.form.get('subject')?.patchValue(event)
        break;
    }
  }

  clearForm(formName: string){
    //set status provvisorio per subject e building
    this.form.get(formName)?.reset()
    this.form.get(formName)?.get('status')?.setValue(this.statusTemporary)
  }

  get addressSubjectValid(){
    let streetName = this.form.get('subject')?.get('streetName')?.valid
    let streetNumber = this.form.get('subject')?.get('streetNumber')?.valid
    let zip = this.form.get('subject')?.get('zip')?.valid
    let city = this.form.get('subject')?.get('city')?.valid
    let state = this.form.get('subject')?.get('state')?.valid
    let country = this.form.get('subject')?.get('country')?.valid

    let addressValid;
    if(!streetName || !streetNumber || !zip || !city || !state || !country){
      addressValid = false;
    }else{
      addressValid = true;
    }
    return addressValid
  }

  get addressBuildingValid(){
    let streetName = this.form.get('building')?.get('streetName')?.valid
    let streetNumber = this.form.get('building')?.get('streetNumber')?.valid
    let zip = this.form.get('building')?.get('zip')?.valid
    let city = this.form.get('building')?.get('city')?.valid
    let state = this.form.get('building')?.get('state')?.valid
    let country = this.form.get('building')?.get('country')?.valid

    let addressValid;
    if(!streetName || !streetNumber || !zip || !city || !state || !country){
      addressValid = false;
    }else{
      addressValid = true;
    }
    return addressValid
  }

  get subjectForm(){
    return this.form.controls['subject'] as UntypedFormGroup
  }

  get buildingForm(){
    return this.form.controls['building'] as UntypedFormGroup
  }

  compareFn(o1: WorkType, o2: WorkType){
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  private setCompanyLocationAndResponsibles(companyCode: string){
    if(companyCode && (this.companies && this.companies.length > 0)){
      let companyLocal = this.companies.find(company => company.code === companyCode)
        try {
          this.form.get('companyLocation')?.setValue(companyLocal!.CompaniesSocietiesRel![0].name)
        } catch (error) {
          this.toastr.error(this.translate.instant('ERROR.COMPANYLOC'))
        }

        try {
          let responsibles = companyLocal!.CompaniesUsersRel!.map((user: User) => user.email)
          let responsible = responsibles.includes(this.authService.user().name) ? this.authService.user().name : responsibles[0]
          this.form.get('responsible')?.setValue(responsible)
        } catch (error) {
          this.toastr.error(this.translate.instant('ERROR.COMPANYRESP'))
        }
    }
  }
}
