<block-ui message="">
  <div class="container-fluid p-0" *ngIf="user()">
    <div class="row g-0 bg-white">
      <div id="sidebarContainer" class="position-fixed" [class.collapsed]="isSidebarCollapsed()">
        <app-sidebar [collapsed]="isSidebarCollapsed()"></app-sidebar>
        <!--
          <app-sidebar-with-sub-menu [collapsed]="isSidebarCollapsed()"></app-sidebar-with-sub-menu>
        -->
      </div>
      <app-navbar style="z-index: 998" [class.collapsed]="isSidebarCollapsed()"></app-navbar>
      <div id="mainContainer" [class.collapsed]="isSidebarCollapsed()">
        <div [ngClass]="{'container-full': planningFullPage(), 'p-5' : !planningFullPage() }" id="content">
          <router-outlet (activate)="libService.unlockPage();" (deactivate)="libService.lockPage('')"></router-outlet>
        </div>
      </div>

      <!-- Chat Bot -->
      <app-chat-bot></app-chat-bot>

    </div>
  </div>
</block-ui>


<!--
  <div *ngIf="user() == null" class="d-flex flex-column flex-root">

    <div class="d-flex flex-column flex-lg-row flex-column-fluid">

      <div class="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" style="background-color: #293683;">


        <div class="d-flex flex-center flex-column flex-column-fluid">

          <div class="w-lg-500px p-10 p-lg-15 mx-auto" style="text-align: center">

            <a href="#" class="py-9 mb-5">
              <img alt="Logo" src="assets/media/logos/edac-logo.png" class="h-175px" />
            </a>

            <h1 class="fw-bolder fs-2qx pb-5 pb-md-10 mt-5" style="color: #fcfcfc;">Benvenuto in EDAC</h1>

            <p class="fw-bold fs-4" style="color: #fcfcfc;">Lorem ipsum dolor sit amet, <br /> elit, sed do eiusmod tempor incididunt <br />ut labore et dolore magna aliqua</p>

          </div>

        </div>

      </div>

      <div class="d-flex flex-column flex-lg-row-fluid py-10">

        <div class="d-flex flex-center flex-column flex-column-fluid">

          <div class="w-lg-500px p-10 p-lg-15 mx-auto">

            <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="#" action="#">

              <div class="text-center mb-10">

                <h1 class="text-dark mb-3">Accedi al tuo account</h1>

              </div>
              <div class="text-center">

                <button (click)="login()" type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5">
                  <span class="indicator-label text-dark">Accedi con AD</span>

                </button>

              </div>

            </form>

          </div>

        </div>

      </div>

    </div>

  </div>
-->

<div *ngIf="user() == null" id="kt_body" class="d-flex flex-column flex-root"
  style="position: absolute; height:100%; width:100%; overflow:auto;">

  <div class="d-flex flex-column flex-lg-row flex-column-fluid">

    <div class="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" style="background-color: #293683;">


      <div class="d-flex flex-center flex-column flex-column-fluid">

        <div class="w-lg-500px p-10 p-lg-15 mx-auto" style="text-align: center">

          <a href="#" class="py-9 mb-5">
            <img alt="Logo" src="assets/media/logos/acrobatica_symbol_giallo.svg" class="h-175px" />
          </a>

          <h1 class="fw-bolder fs-2qx pb-5 pb-md-10 mt-5" style="color: #fcfcfc;margin-top:0!important;">Benvenuto in
            EDAC</h1>

          <p class="fw-bold fs-4" style="color: #fcfcfc;">EdiliziAcrobatica è l’azienda leader in Italia e in Europa nel
            settore dell’edilizia operativa in doppia fune di sicurezza.</p>

        </div>

      </div>

    </div>

    <div class="d-flex flex-column flex-lg-row-fluid py-10" style="width:auto;">

      <div class="d-flex flex-center flex-column flex-column-fluid">

        <div class="w-lg-500px p-10 p-lg-15 mx-auto" *ngIf="!maintenance; else elseBlock">

          <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="#" action="#">

            <div class="text-center mb-10">

              <h1 class="text-dark mb-3">Accedi al tuo account</h1>

            </div>
            <div class="text-center">

              <button (click)="login()" type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5">
                <span class="indicator-label text-dark">Accedi con AD</span>

              </button>

            </div>

          </form>

        </div>

        <!-- MANUTENZIONE -->
        <ng-template #elseBlock>

          <div class="w-lg-500px p-10 p-lg-15 mx-auto" *ngIf="maintenance; else elseBlock">

            <div class="text-center mb-10">

              <h1 class="text-dark mb-3">Sito in Manutenzione</h1>

            </div>

          </div>
        </ng-template>

      </div>

    </div>

  </div>

</div>