import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlockUIModule } from 'ng-block-ui';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubjectComponent } from './subject/subject.component';
import { OrdersComponent } from './orders/orders.component';
import { PracticesComponent } from './practices/practices.component';
import { MySidebarComponent } from './sidebar/sidebar.component';
import { SidebarWithSubMenuComponent } from './sidebar-with-sub-menu/sidebar-with-sub-menu.component'
import { UsersComponent } from './users/users.component';
import { UsersExternalComponent } from './users-external/users.external.component';
import { ProfilesComponent } from './profiles/profiles.component';

import { SettingsComponent } from './settings/settings.component';
import { ActionsComponent } from './settings/actions/actions.component';
import { ActionSettingsComponent } from './settings/action-settings/action-settings.component';
import { ActionProfileSettingsComponent } from './settings/action-profile-settings/action-profile-settings.component';
import { ActionSettingsFormComponent } from './settings/action-settings/action-settings-form/action-settings-form.component';
import { WorkflowSettingsComponent } from './settings/workflow-settings/workflow-settings.component';
import { WorkflowSettingsFormComponent } from './settings/workflow-settings/workflow-settings-form/workflow-settings-form.component';
import { SubjectTypeSettingsComponent } from './settings/subject-type-settings/subject-type-settings.component';
import { SubjectTypeSettingsFormComponent } from './settings/subject-type-settings/subject-type-settings-form/subject-type-settings-form.component';
import { TranslationSettingsComponent } from './settings/translation-settings/translation-settings.component';
import { CalendarSettingsComponent } from './settings/calendar-settings/calendar-settings.component';
import { CalendarContractsComponent } from './settings/calendar-contracts/calendar-contracts.component';
import { AbsenceModulesComponent } from './settings/absence-modules/absence-module.component';
import { TranslationSettingsFormComponent } from './settings/translation-settings/translation-settings-form/translation-settings-form.component';
import { MarketingNoteSettingsComponent } from './settings/marketing-note-settings/marketing-note-settings.component';
import { MarketingNoteSettingsFormComponent } from './settings/marketing-note-settings/marketing-note-settings-form/marketing-note-settings-form.component';
import { ActionFormComponent } from './settings/actions/action-form/action-form.component';
import { SettingsDataTableComponent } from './settings/settings-data-table/settings-data-table.component';
import { SettingsModalFormComponent } from './settings/settings-modal-form/settings-modal-form.component';

import { JourneysComponent } from './journeys/journeys.component';
import { DomainsComponent } from './domains/domains.component';
import { HomComponent } from './hom/hom.component';
import { AdminLicenseOldComponent } from './admin-license-old/admin-license.component';
import { OperatorLicenseComponent } from './operator-license/operator-license.component';
import { CockpitComponent } from './cockpit/cockpit.component';
import {
  /* GanttModule, */ FilterService,
  SortService,
  SelectionService,
  ToolbarService,
  DayMarkersService,
} from '@syncfusion/ej2-angular-gantt';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
//import { ScheduleModule, AgendaService, DayService, WeekService, WorkWeekService, MonthService } from '@syncfusion/ej2-angular-schedule';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { ProfileItemComponent } from './profile-item/profile-item.component';
import { CompaniesComponent } from './companies/companies.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './auth/auth.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { DomainItemComponent } from './domain-item/domain-item.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { CdkTreeModule } from '@angular/cdk/tree';
import { SafeUrlPipe } from './pipes/safeUrl';
import { SafeHtmlPipe } from './pipes/safeHtml';
import { CertificatesComponent } from './certificates/certificates.component';
import { DatainComponent } from './datain/datain.component';
import { PaychecksPerTenComponent } from './paychecks-per-ten/paychecks-per-ten.component';
import { MyHeadquartersComponent } from './my-headquarters/my-headquarters.component';
import { FinalBalancerMultiComponent } from './final-balancer-multi/final-balancer-multi.component';
import { CancellationsApprovalComponent } from './cancellations-approval/cancellations-approval.component';
import { DpiComponent } from './dpi/dpi.component';
import { CheckinVerificationComponent } from './checkin-verification-aapp/checkin-verification.component';
import { CheckinDetailsComponent } from './checkin-verification-aapp/dialog/checkin-details-row.component'
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsDialogComponent } from './notifications/notifications-dialog/notifications-dialog.component';
import { TransferReceiptsComponent } from './transfer-receipts/transfer-receipts.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginatedTableComponent } from './paginated-table/paginated-table.component';
import { DashboardCreateComponent } from './dashboard-create/dashboard-create.component';
import { OrderDtailComponent } from './orders/order-dtail/order-dtail.component';
import { PracticeDtailComponent } from './practices/practice-dtail/practice-dtail.component';
import { ToastrModule } from 'ngx-toastr';
import { AdLicDtailComponent } from './admin-license-old/ad-lic-dtail/ad-lic-dtail.component';
import { CategoriesComponent } from './categories/categories.component';
import { BranchesComponent } from './branches/branches.component';
import { MacrophasesComponent } from './macrophases/macrophases.component';
import { PhasesComponent } from './phases/phases.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { LocationsComponent } from './locations/locations.component';
import { CompanyDtailComponent } from './companies/company-dtail/company-dtail.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
//import { GenConfigComponent } from './gen-config/gen-config.component';
import { MatCardModule } from '@angular/material/card';
import { UserAreaComponent } from './user-area/user-area.component';
import { UserAreaEditModalComponent } from './modals/user-area-edit-modal/user-area-edit-modal.component';
import { ModalForNonProgrammableOrders } from './modals/modal-for-non-programmable-orders/modal-for-non-programmable-orders.component';
import { ModalEstimateDeliveryMethods } from './modals/modal-estimate-delivery-methods/modal-estimate-delivery-methods.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CrmComponent } from './crm/crm.component';
import { CustomerRelationshipManagementComponent } from './customer-relationship-management/customer-relationship-management.component';
import { CrmSectionsComponent } from './crm/crm-sections/crm-sections.component';
import { GanttModule } from '@progress/kendo-angular-gantt';
import { EditEventCalendarDialog } from './settings/calendar-settings/components/edit-event-dialog';
import { ConfirmDeleteDialogComponent } from './settings/calendar-settings/confirm-delete-dialog/confirm-delete-dialog.component';
import { CalendarContractsFormComponent } from './settings/calendar-contracts/calendar-contract-form/calendar-contracts-form';
import { AbsenceModulesFormComponent } from './settings/absence-modules/absence-modules-form/absence-modules-form';



import { IntlModule } from '@progress/kendo-angular-intl';
import { MatBadgeModule } from '@angular/material/badge';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CurrencyPipe } from '@angular/common';
import { WebcamModule } from 'ngx-webcam';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatListModule} from '@angular/material/list';

// Load all required data for the it locale
import '@progress/kendo-angular-intl/locales/it/all';
import '@progress/kendo-angular-intl/locales/it/calendar';
import { UsersLogsComponent } from './modals/users-logs/users-logs.component';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { ClaimComponent } from './claim/claim.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploaderButtonComponent } from './file-uploader-button/file-uploader-button.component';
import { TaxstepsComponent } from './taxsteps/taxsteps.component';
import { FulltextsearchComponent } from './fulltextsearch/fulltextsearch.component';
import { AddressComponent } from './address/address.component';
import { MatButtonModule } from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DialogComponentSubject } from './subject/subject-dtail/dialog/dialog.component';
import { SubjectSectionDialogComponent } from './subject-section/subject-section-detail/subject-section-dialog/subject-section-dialog.component';
import { DialogComponentBuilding } from './building/building-detail/dialog/dialog.component';
import { CrmFormDialogComponent } from './shared/crm-form/crm-form-dialog/crm-form-dialog.component';
import { FileUploadModalComponent } from './modals/file-upload-modal/file-upload-modal.component';
import { WebcamComponent } from './webcam/webcam.component';
import { TeamsComponent } from './teams/teams.component';
import { TeamDetailComponent } from './teams/team-detail/team-detail.component';
import { SurveyModule } from './survey/survey.module';
import { RequestModule } from './request/request.module';
import { FullTextSearchModule } from './fulltextsearch2/fulltextsearch.module';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from './components/components.module';
import { ClaimWorkflowComponent } from './claim-workflow/claim-workflow.component';
import { RapportSectionComponent } from './rapport-section/rapport-section.component';
import { ClaimsComponent } from './claim-workflow/claims/claims.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LegalsComponent } from './claim-workflow/legals/legals.component';
import { ComplaintsComponent } from './claim-workflow/complaints/complaints.component';
import { TransactionsComponent } from './claim-workflow/transactions/transactions.component';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';

import { ObjectivesComponent } from './objectives/objectives.component';
import { DocumentTypesComponent } from './document-types/document-types.component';
import { DocumentWorkflowComponent } from './document-workflow/document-workflow.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { CollectionsComponent } from './collections/collections.component';
import { OrderStatusesComponent } from './order-statuses/order-statuses.component';

import { NgTruncatePipeModule } from 'angular-pipes';

import { SubjectModule } from './subject/subject.module';
import { SubjectSectionModule } from './subject-section/subject-section.module';
import { BuildingModule } from './building/building.module';
import { CreditsComponent } from './credits/credits.component';
import { ActivitiesModule } from './activities/activities.module';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CockpitByProfileComponent } from './cockpit-by-profile/cockpit-by-profile.component';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { OrderFeeComponent } from './order-fee/order-fee.component';
import { CompanyTargetsComponent } from './targets/company-targets/company-targets.component';
import { ProfileTargetsComponent } from './targets/profile-targets/profile-targets.component';
import { TargetsComponent } from './targets/targets.component';
import { DocumentTypeTableComponent } from './modals/file-upload-modal/document-type-table/document-type-table.component';
import { UserTasksComponent } from './user-area/user-tasks/user-tasks.component';
import { Linkv2Service } from './services/linkv2.service';
import { Taskv2Service } from './services/taskv2.service';
import { ErpComponent } from './erp/erp.component';
import { TicketComponent } from './ticket/ticket.component';
import { TargetDetailComponent } from './targets/target-detail/target-detail.component';
import { TargetsListComponent } from './targets/targets-list/targets-list.component';
import { AdminLicenceModule } from './admin-licence/admin-licence.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import { EmailTemplateModule } from './email-template/email-template.module';
import { ClaimsDocumentComponent } from './claim-workflow/claims-document/claims-document.component';
import { SimpleDialogComponent } from './claim-workflow/simple-dialog/simple-dialog.component';
import { OrderDocumentComponent } from './orders/order-dtail/order-document/order-document.component';
import { PracticeDocumentComponent } from './practices/practice-dtail/practice-document/practice-document.component';
import { BranchDetailComponent } from './branches/branch-detail/branch-detail.component';
import { CategoryDetailComponent } from './categories/category-detail/category-detail.component';
import { MacrophaseDetailComponent } from './macrophases/macrophase-detail/macrophase-detail.component';
import { PhaseDetailComponent } from './phases/phase-detail/phase-detail.component';
import { TaxstepDetailComponent } from './taxsteps/taxstep-detail/taxstep-detail.component';
import { BranchCompanyComponent } from './branches/branch-company/branch-company.component';
import { DeleteConfirmationComponent } from './dialogs/delete-confirmation/delete-confirmation.component';
import { OrderDialogComponent } from './claim-workflow/order-dialog/order-dialog.component';
import { SurveysMenuComponent } from './surveys-menu/surveys-menu.component';
import { OrdersEndorsementComponent } from './orders-endorsement/orders-endorsement.component';
import { ActiveInvoicingComponent } from './active-invoicing/active-invoicing.component';
import { DebtCollectionComponent } from './debt-collection/debt-collection.component';
import { PluginFinanceComponent } from './plugin-finance/plugin-finance.component';
import { PluginProduzioneComponent } from './plugin-produzione/plugin-produzione.component';
import { PluginAcquistiComponent } from './plugin-acquisti/plugin-acquisti.component';
import { NewDocumentDialogComponent } from './settings/calendar-contracts/calendar-contract-form/new-document-dialog/new-document-dialog';  // Importa il componente del dialog
import { FileDialogComponent } from './settings/absence-modules/absence-module-document-dialog/absence-module-document-dialog';




import { BryntumSchedulerProModule } from '@bryntum/schedulerpro-angular-thin';
import { BryntumCoreModule } from '@bryntum/core-angular-thin';
import { BryntumCalendarModule } from '@bryntum/calendar-angular-thin';

import { DynamicFieldComponent } from "./dynamic-field/dynamic-field.component";
import { DynamicFormComponent } from "./dynamic-form/dynamic-form.component";
import { DynamicInputComponent } from "./dynamic-field/dynamic-input/dynamic-input.component";
import { DynamicSelectComponent } from "./dynamic-field/dynamic-select/dynamic-select.component";
import { DynamicRadioComponent } from "./dynamic-field/dynamic-radio/dynamic-radio.component";
import { DynamicCheckboxsComponent } from "./dynamic-field/dynamic-checkboxs/dynamic-checkboxs.component";
import { DynamicErrorComponent } from './dynamic-form/dynamic-error/dynamic-error.component';

import { environment } from 'src/environments/environment';


import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat'

import { HistoryBalanceService } from 'src/app/final-balancer/providers/history-balance.service';

import { EventsComponent } from './events/events.component';
import { EventDetailsComponent } from './events/event-details/event-details.component';
import { EventDocumentsComponent } from './events/event-details/event-documents/event-documents.component';
import { EventFormComponent } from './events/event-details/event-form/event-form.component';
import { EventRegistrationsComponent } from './events/event-details/event-registrations/event-registrations.component';
import { EventUploadDocumentModal } from './events/event-details/event-modal/event-upload-document-modal/event-upload-document-modal.component';
import { EventBuildingsComponent } from './events/event-details/event-buildings/event-buildings.component';

import { AddressFormComponent } from './forms/address-form/address-form.component';
import { AddressCompanyModalComponent } from './modals/address-company-dialog/address-company-dialog.component';

import { EventBuildingModal } from './events/event-details/event-modal/event-building-modal/event-building-modal.component';
import { BuildingFormComponent } from './forms/building-form/building-form.component';

import { BuildingModal } from './modals/building-modal/building-modal.component';


import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { ModalFormComponent } from './forms/modal-form/modal-form.component';
import { ComplaintFormComponent } from './forms/complaint-form/complaint-form.component';
import { ClaimFormComponent } from './forms/claim-form/claim-form.component';
import { LegalFormComponent } from './forms/legal-form/legal-form.component';
import { TransactionFormComponent } from './forms/transaction-form/transaction-form.component';
import { ReviewFormComponent } from './forms/review-form/review-form.component';
import { GrievanceFormComponent } from './forms/grievance-form/grievance-form.component';
import { PecFormComponent } from './forms/pec-form/pec-form.component';

import { ReviewsComponent } from './reviews/reviews.component';

import { GrievancesComponent } from './grievances/grievances.component';

import { PecsComponent } from './pecs/pecs.component';
import { PecListsComponent } from './pecs/pec-lists/pec-lists.component';
import { PecActionModalComponent } from './pecs/pec-action-modal/pec-action-modal.component';
import { AssociationsBetweenPecsComponent } from './pecs/associations-between-pecs/associations-between-pecs.component';
import { PecAssociationModalComponent } from './pecs/pec-association-modal/pec-association-modal.component';

import {SearchOrCreateUserComponent} from './search-or-create-user/search-or-create-user.component';
import { ConventionsComponent } from 'src/app/conventions/conventions.component';
import { ConventionsFormComponent } from 'src/app/conventions/conventions-form/conventions-form.component';
import { ConventionsSubjectApprovalsComponent } from 'src/app/conventions/conventions-subject-approvals/conventions-subject-approvals.component';

import { SubjectFulltextsearchComponent } from 'src/app/subject-fulltextsearch/subject-fulltextsearch.component';

import { SubjectRelationModalComponent } from 'src/app/modals/subject-relation-modal/subject-relation-modal.component';
import { AddConventionModalComponent } from 'src/app/modals/add-convention-modal/add-convention-modal.component';



import { ChatBotComponent } from './chat-bot/chat-bot.component';

/*
import { WorktypesComponent } from './worktypes/worktypes.component';
import { WorktypeDetailComponent } from './worktypes/worktype-detail/worktype-detail.component';
import { ActivityTypesComponent } from './activity-types/activity-types.component';
import { ActivityTypesDetailComponent } from './activity-types/activity-types-detail/activity-types-detail.component';
import { ActivityReasonsComponent } from './activity-reasons/activity-reasons.component';
import { ActivityReasonsDetailComponent } from './activity-reasons/activity-reasons-detail/activity-reasons-detail.component';
import { CostsConfigComponent } from './costs-config/costs-config.component';
import { CostsConfigDetailComponent } from './costs-config/costs-config-detail/costs-config-detail.component';
import { LevelsComponent } from './levels/levels.component';
import { LevelsDetailComponent } from './levels/levels-detail/levels-detail.component';
import { WorkCausalsComponent } from './work-causals/work-causals.component';
import { WorkCausalsDetailComponent } from './work-causals/work-causals-detail/work-causals-detail.component';
*/
// import { OrdersFilterComponent } from './shared/orders-filter/orders-filter.component';

// import { FullTextSearchModule2 } from './fulltextsearch3/fulltextsearch3.module';
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeEs);

// required for AOT compilation
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    DynamicFieldComponent,
    DynamicFormComponent,
    DynamicInputComponent,
    DynamicSelectComponent,
    DynamicRadioComponent,
    DynamicCheckboxsComponent,
    DynamicErrorComponent,

    EventsComponent,
    EventDetailsComponent,
    EventDocumentsComponent,
    EventFormComponent,
    EventRegistrationsComponent,
    EventUploadDocumentModal,
    EventBuildingsComponent,
    EventBuildingModal,
    BuildingModal,

    ChatBotComponent,

    AddressFormComponent,
    AddressCompanyModalComponent,
    BuildingFormComponent,
    ModalFormComponent,
    ComplaintFormComponent,
    ClaimFormComponent,
    LegalFormComponent,
    TransactionFormComponent,
    ReviewFormComponent,
    GrievanceFormComponent,
    PecFormComponent,

    ReviewsComponent,

    GrievancesComponent,

    PecsComponent,
    PecListsComponent,
    PecActionModalComponent,
    AssociationsBetweenPecsComponent,
    PecAssociationModalComponent,

    SearchOrCreateUserComponent,
    ConventionsComponent,
    ConventionsFormComponent,
    ConventionsSubjectApprovalsComponent,

    SubjectRelationModalComponent,
    AddConventionModalComponent,

    SubjectFulltextsearchComponent,
    EventsComponent,
    EventDetailsComponent,
    EventFormComponent,
    EventRegistrationsComponent,
    EventUploadDocumentModal,
    EventDocumentsComponent,
    EventBuildingsComponent,
    EventBuildingModal,
    BuildingModal,

    AddressFormComponent,
    AddressCompanyModalComponent,
    BuildingFormComponent,
    
    ClaimsDocumentComponent,
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    // SubjectComponent,
    OrdersComponent,
    PracticesComponent,
    // OrdersFilterComponent,
    MySidebarComponent,
    SidebarWithSubMenuComponent,
    UsersComponent,
    UsersExternalComponent,
    ProfilesComponent,

    SettingsComponent,
    ActionsComponent,
    ActionSettingsComponent,
    ActionProfileSettingsComponent,
    ActionSettingsFormComponent,
    WorkflowSettingsComponent,
    WorkflowSettingsFormComponent,
    SubjectTypeSettingsComponent,
    SubjectTypeSettingsFormComponent,
    TranslationSettingsComponent,
    CalendarSettingsComponent,
    CalendarContractsComponent,
    AbsenceModulesComponent,
    TranslationSettingsFormComponent,
    MarketingNoteSettingsComponent,
    MarketingNoteSettingsFormComponent,
    ActionFormComponent,
    SettingsDataTableComponent,
    SettingsModalFormComponent,
    
    JourneysComponent,
    DomainsComponent,
    HomComponent,
    AdminLicenseOldComponent,
    OperatorLicenseComponent,
    CockpitComponent,
    UserMenuComponent,
    ProfileItemComponent,
    CompaniesComponent,
    NavbarComponent,
    DomainItemComponent,
    CertificatesComponent,
    DatainComponent,
    PaychecksPerTenComponent,
    MyHeadquartersComponent,
    FinalBalancerMultiComponent,
    CancellationsApprovalComponent,
    DpiComponent,
    CheckinVerificationComponent,
    CheckinDetailsComponent,
    NotificationsComponent,
    NotificationsDialogComponent,
    EditEventCalendarDialog,
    CalendarContractsFormComponent,
    AbsenceModulesFormComponent,
    ConfirmDeleteDialogComponent,
    TransferReceiptsComponent,
    PaginatedTableComponent,
    DashboardCreateComponent,
    OrderDtailComponent,
    PracticeDtailComponent,
    AdLicDtailComponent,
    CategoriesComponent,
    BranchesComponent,
    MacrophasesComponent,
    PhasesComponent,
    DashboardListComponent,
    LocationsComponent,
    CompanyDtailComponent,
   // GenConfigComponent,
    UserAreaComponent,
    UserAreaEditModalComponent,
    ModalForNonProgrammableOrders,
    ModalEstimateDeliveryMethods,
    CrmComponent,
    CustomerRelationshipManagementComponent,
    CrmSectionsComponent,
    UsersLogsComponent,
    ClaimListComponent,
    ClaimComponent,
    FileUploaderButtonComponent,
    TaxstepsComponent,
    FulltextsearchComponent,
    AddressComponent,
    DialogComponentSubject,
    SubjectSectionDialogComponent,
    DialogComponentBuilding,
    CrmFormDialogComponent,
    FileUploadModalComponent,
    //Fulltextsearch2Component,
    // SubjectDetailComponent,
    // SubjectLocalizationComponent,
    // SubjectDataComponent,
    // SubjectConsentsComponent,
    // SubjectDocumentsComponent,
    // SubjectSurveyComponent,
    // SubjectRelationsComponent,
    // SubjectMarketingComponent,
    TeamsComponent,
    TeamDetailComponent,
    WebcamComponent,
    // ActivitiesComponent,
    // ActivitiesDetailComponent,
    // FormfieldSearchComponent,
    // TypeRadioComponent,
    ClaimWorkflowComponent,
    RapportSectionComponent,
    ClaimsComponent,
    LegalsComponent,
    ComplaintsComponent,
    TransactionsComponent,
    //SubjectFilterComponent,
    ObjectivesComponent,
    DocumentTypesComponent,
    DocumentWorkflowComponent,
    InvoicesComponent,
    CollectionsComponent,
    OrderStatusesComponent,
    // SubjectsPracticesComponent,
    CreditsComponent,
    CockpitByProfileComponent,
    DashboardViewComponent,
    OrderFeeComponent,
    TargetsComponent,
    ProfileTargetsComponent,
    CompanyTargetsComponent,
    DocumentTypeTableComponent,
    UserTasksComponent,
    TicketComponent,
    ErpComponent,
    TargetDetailComponent,
    TargetsListComponent,
    SimpleDialogComponent,
    OrderDocumentComponent,
    PracticeDocumentComponent,
    OrderDialogComponent,
    BranchDetailComponent,
    CategoryDetailComponent,
    MacrophaseDetailComponent,
    PhaseDetailComponent,
    BranchCompanyComponent,
    DeleteConfirmationComponent,
    TaxstepDetailComponent,
    SurveysMenuComponent,
    OrdersEndorsementComponent,
    ActiveInvoicingComponent,
    DebtCollectionComponent,
    PluginFinanceComponent,
    PluginProduzioneComponent,
    PluginAcquistiComponent,
    NewDocumentDialogComponent,
    FileDialogComponent
    /*
    WorktypesComponent,
    WorktypeDetailComponent,
    ActivityTypesComponent,
    ActivityTypesDetailComponent,
    ActivityReasonsComponent,
    ActivityReasonsDetailComponent,
    CostsConfigComponent,
    CostsConfigDetailComponent,
    LevelsComponent,
    LevelsDetailComponent,
    WorkCausalsComponent,
    WorkCausalsDetailComponent,
    */
  ],
  imports: [
    // FlexLayoutModule,
    MatButtonToggleModule,
    BryntumSchedulerProModule,
    BryntumCoreModule,
    BryntumCalendarModule,
    ImageCropperModule,
    DateInputsModule,
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    SidebarModule,
    CommonModule,
    TreeViewModule,
    TextBoxModule,
    ExcelModule,
    FormsModule,
    QueryBuilderModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTableModule,
    MatToolbarModule,
    MatSortModule,
    MatIconModule,
    DragDropModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatSliderModule,
    MatTreeModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatPaginatorModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatSlideToggleModule,
    CdkTreeModule,
    MatCheckboxModule,
    MatGridListModule,
    MatDialogModule,
    BrowserAnimationsModule,
    DropDownButtonModule,
    InputsModule,
    NgTruncatePipeModule,
    DropDownsModule,
    MatBadgeModule,
    WebcamModule,
    LabelModule,
    FileUploadModule,
    MatListModule,
    MatButtonToggleModule,
    OAuthModule.forRoot(),
    BlockUIModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    GooglePlaceModule,
    GoogleMapsModule,
    GanttModule,
    IntlModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      countDuplicates: false,
      extendedTimeOut: 5000,
      positionClass: 'toast-top-right',
    }),
    SurveyModule,
    RequestModule,
    FullTextSearchModule,
    // FullTextSearchModule2,
    SharedModule,
    ComponentsModule,
    GridModule,
    SubjectModule,
    SubjectSectionModule,
    BuildingModule,
    ActivitiesModule,
    AdminLicenceModule,
    EmailTemplateModule,
    //OrderModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig)
  ],
  providers: [
    CurrencyPipe,
    //AgendaService,
    //DayService,
    //WeekService,
    //WorkWeekService,
    //MonthService,
    FilterService,
    SortService,
    SelectionService,
    ToolbarService,
    DayMarkersService,
    Taskv2Service,
    Linkv2Service,
    // LocalEditService,
    DatePipe,
    MatDatepickerModule,
    MatNativeDateModule,
    AuthService,
    HistoryBalanceService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
    { provide: LOCALE_ID, useValue: 'it-IT' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}


  
