<ng-container [formGroup]="parameter">
    <div [ngSwitch]="parameter.get('type')?.value">


        <!-- BOOLEAN -->
        <p *ngSwitchCase="'boolean'">
            <mat-slide-toggle color="primary" formControlName="value">{{parameter.get('key').value}}</mat-slide-toggle>
        </p>


        <!-- NUMBER -->
        <p *ngSwitchCase="'number'">

            <container-element [ngSwitch]="parameter.get('key').value">


                <!-- DOCUMENT TYPE (ID) -->
                <some-element *ngSwitchCase="'documentTypeId'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Document Type</mat-label>
                        <mat-select formControlName="value">

                            <mat-option *ngFor="let documentType of selectFields.documentTypes"
                                [value]="documentType.id">{{documentType.code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- NEXT STATE (ID) -->
                <some-element *ngSwitchCase="'nextStateId'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Next State</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let state of selectFields.currentStates"
                                [value]="state.id">{{getStatusLabel(state.id)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- NEXT WORKFLOW (ID) -->
                <some-element *ngSwitchCase="'practiceWorkflowId'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Next Workflow</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let workflow of selectFields.workflows"
                                [value]="workflow.id">{{workflow.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- DEFAULT -->
                <some-element *ngSwitchDefault>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{parameter.get('key').value}}</mat-label>
                        <input matInput formControlName="value">
                    </mat-form-field>
                </some-element>

            </container-element>


        </p>

        <!-- STRING -->
        <p *ngSwitchCase="'string'">
            <container-element [ngSwitch]="parameter.get('key').value">


                <!-- START WORKFLOW (CODE) -->
                <some-element *ngSwitchCase="'startWorkflowCode'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Start Workflow</mat-label>
                        <mat-select formControlName="value" (selectionChange)="onChangeStartWorkflowCode($event)">
                            <mat-option *ngFor="let workflow of selectFields.workflows"
                                [value]="workflow.code">{{workflow.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- RELATIONSHIP TYPE (CODE) -->
                <some-element *ngSwitchCase="'typologiesRelationshipsBetweenPracticeCode'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Relationship Type</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let type of selectFields.typologiesRelationshipsBetweenPractices"
                                [value]="type.code">{{getRelationshipTypeLabel(type)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- START STATE (CODE) -->
                <some-element *ngSwitchCase="'startStateCode'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Start State</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let state of selectFields.stateCategoriesRelationPractices"
                                [value]="state.code">{{getStatusLabel(state.id)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- NEXT STATE (CODE) -->
                <some-element *ngSwitchCase="'nextStateCode'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Next State</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let state of selectFields.currentStates"
                                [value]="state.code">{{getStatusLabel(state.id)}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- REASON (CODE) -->
                <some-element *ngSwitchCase="'reasonCode'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Reason</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let reason of selectFields.reasons"
                                [value]="reason.code">{{reason.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- DOCUMENT TYPE (CODE) -->
                <some-element *ngSwitchCase="'documentTypeCode'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Document
                            Type</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let documentType of selectFields.documentTypes"
                                [value]="documentType.code">{{documentType.code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>


                <!-- TRANSLATION (CODE) -->
                <some-element *ngSwitchCase="'translationGroupCode'">
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>Translation</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let translationGroup of selectFields.translationGroups"
                                [value]="translationGroup.code">{{translationGroup.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </some-element>



                <!-- DEFAULT -->
                <some-element *ngSwitchDefault>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{parameter.get('key').value}}</mat-label>
                        <input matInput formControlName="value">
                    </mat-form-field>
                </some-element>

            </container-element>


        </p>

    </div>


</ng-container>