<div class="chat-button-container">
  <button mat-fab color="primary" (click)="toggleChat()">
    <mat-icon>{{ chatVisible ? 'close' : 'chat' }}</mat-icon>
  </button>
</div>

<div class="chat-container" *ngIf="chatVisible" cdkDrag>
  <div class="chat-header">
    <h3 style="color:#f7ea48;">Acrobottino</h3>
    <div class="header-buttons">
      <button mat-icon-button cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </button>
      <button mat-icon-button (click)="toggleChat()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="chat-messages" #chatMessages>
    <div *ngFor="let message of messages; index as i" class="message-container"
      [ngClass]="{'user-message': message.user, 'bot-message': !message.user}">
      <img *ngIf="!message.user" src="../../assets/img/acrobot.jpg" class="avatar" alt="Bot Avatar">
      <div class="message-text" [class.marginBottom25]="!message.user && i != 0">
        {{ message.text }}

        <div *ngIf="message?.manuals?.length > 0">

          <div *ngFor="let manual of message?.manuals" class="manualBox">

            <div *ngIf="manual?.images?.length > 0">
              <p>Immagini di riferimento. (Clicca sull'immagine per ingrandire)</p>
              <img class="manualImg" [src]="imageUrl" *ngFor="let imageUrl of manual?.images" (click)="openImageDialog(imageUrl)">
            </div>

            <p>Puoi intregare queste informazioni con il manuale alla pagina <strong>{{ manual?.pageNumber}}</strong>
            </p>
            <a href="{{manual?.fileUrl}}">{{ manual?.name }}</a>

          </div>

        </div>

      </div>
      <div *ngIf="!message.user && i != 0" class="feedback-buttons">
        <span>È stato utile?</span>
        <button mat-icon-button (click)="sendFeedback(true)" class="small-button">
          <mat-icon>thumb_up</mat-icon>
        </button>
        <button mat-icon-button (click)="sendFeedback(false)" class="small-button">
          <mat-icon>thumb_down</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="loading" class="loading-spinner">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>
  <div class="chat-input">
    <input [(ngModel)]="userInput" (keyup.enter)="sendMessage()" placeholder="Scrivi un messaggio..." />
    <button mat-icon-button (click)="sendMessage()">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>