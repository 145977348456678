<div [formGroup]="component">
    <mat-accordion *ngIf="showCategory(component.get('id')?.value)">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{component.get('code').value}}
                </mat-panel-title>
                <mat-panel-description>
                    Attributi: {{component.get('attributes').value.length}}
                </mat-panel-description>
            </mat-expansion-panel-header>


            <!-- Attributi Componente -->
            <ng-container formArrayName="attributes">
                <div class="cardBox" *ngFor="let attribute of component.get('attributes').controls; let c = index">

                    <app-component-attribute-card-box [form]="form" [attribute]="attribute" [index]="c" [selectFields]="selectFields"></app-component-attribute-card-box>

                </div>
            </ng-container>


        </mat-expansion-panel>
    </mat-accordion>
</div>