import { Component, EventEmitter, Input, OnChanges, AfterViewInit, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-component-attribute-card-box',
  templateUrl: './component-attribute-card-box.component.html',
  styleUrls: ['./component-attribute-card-box.component.scss']
})
export class ComponentAttributeCardBoxComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() index: number = 0;
  @Input() attribute: any;
  @Input() selectFields: any = {};


  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
  ) { }

  get components(): FormArray {
    return <FormArray> this.form.get('components');
  }

  ngOnInit(): void {

  };

  addConventionSettings(attribute: any) {
    console.log('addConventionSettings');
    let groupCode = uuidv4();
    let parametersArray: any = []
    let parameterStructure = attribute.get('parameterStructure').value
    if (parameterStructure) {
      parameterStructure.forEach((parameter: any) => {
        let tmpStructure = this.addParameterGroupCode(parameter, null, groupCode)
        parametersArray.push(tmpStructure)
      })
    }


    attribute.get('groupCodes').push(this.fb.group({
      codeGroup: groupCode,
      parameters: this.fb.array(parametersArray),
    }))
    console.log(attribute)
  }

  addParameterGroupCode(values: any, value: any, groupCode: any = null): FormGroup {

    let tmpValue = value || null
    let tmpGroupCode = groupCode || values.groupCode
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
      groupCode: [tmpGroupCode]
    });
  }

  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }

}
