<ng-container [formGroup]="attribute">
    <mat-card class="cardItem">
      <mat-card-header>
        <mat-card-title>{{attribute.get('code')?.value}}</mat-card-title>
        <mat-card-subtitle>
          <!--
          code: {{attribute.get('code').value}}<br>
          id: {{attribute.get('id').value}}<br>
          multiple: {{attribute.get('multiple').value}}<br>
          -->
          <mat-slide-toggle color="primary"
              formControlName="active">Attivo</mat-slide-toggle>
      </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
  
        <!-- Attributi Attivi e Multipli -->
        <ng-container formArrayName="groupCodes"
          *ngIf="attribute.get('active')?.value && attribute.get('multiple')?.value">
  
          <button *ngIf="attribute.get('multiple').value" matTooltip="Back" type="button"
            class="my-bounce btn btn-primary mx-2" (click)="addConventionSettings(attribute)">
            Aggiungi
          </button>
  
          <div *ngFor="let groupCode of attribute.get('groupCodes')?.controls; let gc = index">
            <ng-container [formGroup]="groupCode">
              <div class="boxParameters" *ngIf="groupCode?.get('parameters')?.controls">
  
  
                <ng-container formArrayName="parameters">
                  <div *ngFor="let parameter of groupCode?.get('parameters')?.controls; let p = index">
                    <app-component-fields [form]="form" [parameter]="parameter" [index]="p" [selectFields]="selectFields"></app-component-fields>
                  </div>
  
                </ng-container>
  
  
              </div>
            </ng-container>
          </div>
  
        </ng-container>
  
        <!-- Attributi Attivi e non Multipli -->
        <ng-container formArrayName="parameters"
          *ngIf="attribute.get('active')?.value && !attribute.get('multiple')?.value">
          <div *ngFor="let parameter of attribute?.get('parameters')?.controls; let p = index">
            <app-component-fields [form]="form" [parameter]="parameter" [index]="p" [selectFields]="selectFields"></app-component-fields>
          </div>
  
        </ng-container>
  
  
      </mat-card-content>
    </mat-card>
  </ng-container>
