<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'ACTIONS.name' | translate }}</mat-label>
        <input matInput formControlName="name" (focusout)="setCode()">
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'ACTIONS.code' | translate }}</mat-label>
        <input readonly matInput formControlName="keyFrontEnd">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Category</mat-label>
        <mat-select formControlName="actionCategoryId">
            <mat-option *ngFor="let actionCategory of actionCategories"
                [value]="actionCategory.id">{{actionCategory.code}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Profili</mat-label>
        <mat-select formControlName="profileIds" multiple>
            <mat-option *ngFor="let profile of profiles" [value]="profile.id">{{profile.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Workflow</mat-label>
        <mat-select formControlName="workflowIds" (selectionChange)="onChangeWorkflow($event)" multiple>
            <mat-option *ngFor="let workflow of workflows" [value]="workflow.id">{{workflow.label}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Stati</mat-label>
        <mat-select formControlName="stateIds" multiple>
            <mat-option *ngFor="let state of currentStates" [value]="state.id"
                [disabled]="getStatusDisabled(state.id)">{{getStatusLabel(state.id)}}</mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Componenti</mat-label>
        <mat-select formControlName="componentIds" multiple>
            <mat-option *ngFor="let component of componentList" [value]="component.id">{{component.code}}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- SEZIONE COMPONETI -->
    <ng-container formArrayName="components">
        <ng-container *ngFor="let component of components.controls; let i = index">
            <app-component-card-box [form]="form" [component]="component" [index]="i" [selectFields]="selectFields"
                class="form-subject"></app-component-card-box>
        </ng-container>
    </ng-container>

    <!-- SEZIONE COPIA -->
    <div class="form-subject">
        <br>
        <br>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Only for Copy</mat-card-title>
            </mat-card-header>
            <mat-card-content>

                <mat-form-field class="form-subject">
                    <mat-label>Workflow</mat-label>
                    <mat-select formControlName="copyWorkflow" (selectionChange)="onChangeWorkflowCopy($event)">
                        <mat-option *ngFor="let workflow of workflows"
                            [value]="workflow.id">{{workflow.label}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>Stati</mat-label>
                    <mat-select formControlName="copyStateIds" multiple>
                        <mat-option *ngFor="let state of currentStatesCopy"
                            [value]="state.id">{{getStatusLabel(state.id)}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>{{ 'ACTIONS.name' | translate }}</mat-label>
                    <input matInput formControlName="copyName" (focusout)="setCodeCopy()">
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>{{ 'ACTIONS.code' | translate }}</mat-label>
                    <input readonly matInput formControlName="copyKeyFrontEnd">
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="copyActionCategoryId">
                        <mat-option *ngFor="let actionCategory of actionCategories"
                            [value]="actionCategory.id">{{actionCategory.code}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </mat-card-content>
            <mat-card-actions align="end">
                <mat-icon (click)="copy()" class="material-icons" style="color:#293683;">content_copy</mat-icon>
            </mat-card-actions>
        </mat-card>
    </div>
</form>





<div class="buttons-right  my-2">

    <button style="background-color:transparent;" matTooltip="Back" type="button" class="my-bounce btn btn-primary mx-2"
        (click)="back()">
        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
    </button>

    <button style="background-color:transparent;" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" *ngIf="hasSave">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

</div>