import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  baseApi = environment.rootApiChatbot;

  constructor(public libService: LibService) { }

  askTheChatbot(unique_id: any, question: any) {
    let params = {
      unique_id: unique_id,
      question: question
    }
    return this.libService.postData<any>(
      params,
      this.baseApi,
      `single_agent/chatbot/`
    );
  }

  chatbotHistory(unique_id: any, status: any) {
    console.log(status)
    let action = status ? "like" : "dislike"
    console.log(action)
    let params = { action: action }
    return this.libService.putData<any>(
      params,
      this.baseApi,
      `single_agent/history/?id=${unique_id}`
    );
  }


}
