import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Inject  } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';
import { ChatbotService } from '../services/chatbot.service';
import { UserSessionService } from '../services/user-session.service';
import { v4 as uuidv4 } from 'uuid';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {

  @ViewChild('chatMessages') chatMessages: ElementRef;

  chatVisible: boolean = false;
  messages: { text: string, user: boolean, manuals?: any }[] = [{ text: 'Ciao, sono Acrobottino. Come posso aiutarti? ', user: false }];
  userInput: string = '';
  loading: boolean = false;

  constructor(public navService: NavService,
    public translate: TranslatorService,
    public chatbotService: ChatbotService,
    public userSessionService: UserSessionService,
    private matDialog: MatDialog
  ) {}

  toggleChat() {
    let usersessionId = uuidv4()

    this.chatVisible = !this.chatVisible;

    // reset userChatbotSession
    if (this.chatVisible) {
      this.userSessionService.saveState('userChatbotSession', usersessionId);
    } else {
      this.userSessionService.saveState('userChatbotSession', null);
    }

    if (this.chatVisible) {
      setTimeout(() => this.scrollChatToBottom(), 0);
    }
  }

  sendMessage() {
    if (this.userInput.trim()) {
      let currentMessage = this.userInput;
      this.messages.push({ text: currentMessage, user: true });
      this.userInput = '';
      this.loading = true;
      let userChatbotSession = this.userSessionService.getState('userChatbotSession');
      this.chatbotService.askTheChatbot(userChatbotSession, currentMessage).subscribe((res) => {
        console.log(res)
        this.loading = false;
        let manuals: any = []
        
        if (res?.page_numbers?.length > 0) {
          res?.page_numbers.forEach((manual: any) => {
            manuals.push({
              name: manual.file_name,
              fileUrl: manual.file_url,
              pageNumber: manual.page_number,
              images: manual.image_url
            })
          });
        }


        setTimeout(() => this.scrollChatToBottom(), 0);
        this.getBotResponse(res.answer, manuals);
      });
    }
  }

  getBotResponse(answer: string, manuals: any) {
    // Simulate a bot response
    setTimeout(() => {
      this.messages.push({ text: answer, user: false, manuals: manuals });
      this.scrollChatToBottom();
    }, 1000);
  }

  sendFeedback(status: boolean) {
    let userChatbotSession = this.userSessionService.getState('userChatbotSession');
    this.chatbotService.chatbotHistory(userChatbotSession, status).subscribe((res) => {
      console.log(res)
    });
  }

  scrollChatToBottom() {
    try {
      this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
    } catch (err) { }
  }

  openImageDialog(imageUrl: string): void {
    this.matDialog.open(ImageDialogComponent, {
      data: { imageUrl: imageUrl },
      width: '80%',
      maxWidth: '800px',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  ngOnInit(): void {
  }

}

// Aggiungi un componente per il dialog dell'immagine
@Component({
  selector: 'image-dialog',
  template: `
    <div mat-dialog-content>
      <img [src]="data.imageUrl" alt="Immagine" style="width: 100%;">
    </div>
  `,
})
export class ImageDialogComponent {
  constructor(
      public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}