<div class="modal fade" id="manageProfilesModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'USERS.USER_PROFILES' | translate }}</h5>
                <button #closeModalProfiles type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Chiudi"></button>
            </div>
            <div class="modal-body" style="display: flex;">
                <div class="mb-3" class="double-dropdown-align">
                    <div style="width: 100%">
                        <h5>{{ 'USERS.USER_PROFILES' | translate }}</h5>
                        <ng-multiselect-dropdown [placeholder]="'Profili'" [settings]="dropdownProfilesSettings"
                            [data]="dropdownListProfiles" [(ngModel)]="selectedProfiles"
                            (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="manageCompaniesModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'USERS.USER_COMPANIES' | translate }}</h5>
                <button #closeModalCompanies type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Chiudi"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <ng-multiselect-dropdown [placeholder]="'Sedi'" [settings]="dropdownCompaniesSettings"
                        [data]="dropdownListCompanies" [(ngModel)]="selectedCompanies"
                        (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="manageLicencesModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'LABEL.LICENCES' | translate }}</h5>
                <button #closeModalLicences type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Chiudi"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <ng-multiselect-dropdown [placeholder]="translate.instant('LABEL.LICENCES')" [settings]="dropdownLicencesSettings"
                        [data]="dropdownListLicences" [(ngModel)]="selectedLicences"
                        (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="manageDomainsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'USERS.USER_DOMAINS' | translate }}</h5>
                <button #closeModalDomains type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Chiudi"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <ng-multiselect-dropdown [placeholder]="'Domini'" [settings]="dropdownDomainsSettings"
                        [data]="dropdownListDomains" [(ngModel)]="selectedDomains" (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid my-container">
    <div class="search-wrapper mat-elevation-z2">
        <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
            <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
            [placeholder]="translate.instant('LABEL.SEARCH')"/>
        </div>
    </div>
    <div style="overflow-x: auto;">
        <table mat-table [dataSource]="dataSource" matSort style="width:100%;" (matSortChange)="updateUserData()">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">ID</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USERS.USERNAME_SYSTEM' | translate }}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.name}} {{ element.surname }}</b></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="locale">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USERS.LOCALE' | translate }}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.locale}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USERS.EMAIL' | translate }}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.email}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USERS.FIRST_LOGIN_DATE' | translate }}
                </th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="lastlogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USERS.LAST_LOGIN_DATE' | translate }}
                </th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.lastlogin | date}}</span>
                </td>
            </ng-container>

            


            <ng-container matColumnDef="profile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered"></th>
                <td matTooltip="{{ 'TOOLTIP.UTENTI.PROFILI_UTENTE' | translate }}" class="tdgf" mat-cell *matCellDef="let element">
                    <button
                        (click)="setActiveUser(element)" 
                        type="button"
                        class="btn" 
                        style="background-color:transparent;" 
                        data-bs-toggle="modal"
                        data-bs-target="#manageProfilesModal"  
                        (click)="selectProfile(element)">
                        <span class="my-bounce material-icons"
                        >face</span>
                    </button>
                </td>
            </ng-container>

            <!--
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data creazione</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data ultima modifica</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
            </td>
        </ng-container>
    -->
            <ng-container matColumnDef="saveAction">
                <th mat-header-cell *matHeaderCellDef></th>
                <td class="tdgf" mat-cell *matCellDef="let element">
                    <span *ngIf="noEdit(element) == true"
                        class="my-bounce material-icons ic">
                        save
                    </span>
                    <span *ngIf="noEdit(element) == false"
                        class="my-bounce material-icons icsave" (click)="saveUser(element)">
                        save
                    </span>
            </ng-container>
            <!--
        <ng-container matColumnDef="deleteAction">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><i class="material-icons ic">delete</i>
        </ng-container>
    -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" (page)="updateUserData()">
    </mat-paginator>
</div>
