import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms'; // Import per FormControl
import { ToastrService } from 'ngx-toastr';
import { UserCheckinService } from '../services/user-checkin.services';
import { ArmilisService } from '../services/armilis.service';
import { UserSessionService } from '../services/user-session.service';
import { CountryService } from '../services/country.service'; // Servizio per ottenere i country
import { CompanyService } from '../services/companyService';
import { tap } from 'rxjs/operators';
import { LibService } from '../services/libService';
import { MatDialog } from '@angular/material/dialog';
import { CheckinDetailsComponent } from './dialog/checkin-details-row.component'
import { faCheckCircle, faTimesCircle, faHardHat } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-checkin-verification',
  templateUrl: './checkin-verification.component.html',
  styleUrls: ['./checkin-verification.component.scss']
})
export class CheckinVerificationComponent implements OnInit {
  displayedColumns = ['id', 'userName', 'createdAt', 'isValidCheckin', 'verify', 'details'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  faCheckCircle = faCheckCircle; // Icona per i check-in validi
  faTimesCircle = faTimesCircle; // Icona per i check-in non validi
  faHardHat = faHardHat; // Icona per i dettagli DPI

  totalRows: number = 20;
  pageSize: number = 10;
  currentPage: number = 0;
  pageSizeOptions: number[] = [5, 10, 20];
  pageInit: any;
  countries: any[] = [];
  societies: any[] = [];
  filteredSocieties: any[] = [];
  companies: any[] = [];
  filteredCompanies: any[] = [];
  countryControl = new FormControl(null);
  societyControl = new FormControl(null);
  companyControl = new FormControl(null);
  societySearchControl = new FormControl('');
  companySearchControl = new FormControl('');
  users: any[] = []; // Lista completa degli utenti
  filteredUsers: any[] = []; // Lista filtrata degli utenti
  userSearchControl = new FormControl(''); // FormControl per la ricerca utenti
  userControl = new FormControl(null); // FormControl per il filtro utenti
  startDateControl = new FormControl(null); // Valore iniziale nullo



  constructor(
    private checkinService: UserCheckinService,
    private armilisService: ArmilisService,
    private toastr: ToastrService,
    private userSessionService: UserSessionService,
    private countryService: CountryService, // Servizio per ottenere i country
    private companyService: CompanyService,
    public libService: LibService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadCountries(); // Carica le countries
    this.loadSocieties();
    this.loadCompanies();
    this.getUsers(); // Carica la lista degli utenti
    this.loadCheckins();

    // Aggiorna le societies quando cambia il country
    this.countryControl.valueChanges.subscribe((selectedCountries: any) => {
      this.filterSocietiesByCountry(selectedCountries);
    });

    // Aggiorna le companies quando cambia la society
    this.societyControl.valueChanges.subscribe((selectedSociety) => {
      this.filterCompaniesBySociety(selectedSociety);
    });

    // Filtra le societies e le companies in base al testo inserito
    this.societySearchControl.valueChanges.subscribe((searchTerm: any) => {
      this.filterSocieties(searchTerm);
    });
    this.companySearchControl.valueChanges.subscribe((searchTerm: any) => {
      this.filterCompanies(searchTerm);
    });

    // Filtra gli utenti in base al testo inserito
    this.userSearchControl.valueChanges.subscribe((searchTerm: any) => {
      this.filterUsers(searchTerm);
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  openDpiDetailsModal(checkin: any): void {
    this.dialog.open(CheckinDetailsComponent, {
      width: '600px',
      data: {
        rows: checkin.rows, // Passa i dati dei DPI al modale
        headerId: checkin.id, // Passa l'ID dell'header per riferimento
        userName: checkin.userName, // Altri dati opzionali
      },
    });
  }


  // Metodo per caricare i check-in con paginazione
  loadCheckins() {
    this.libService.lockPage('');

    const filters = {
      country: this.countryControl.value, // Filtro country
      societa: this.societyControl.value,
      sede: this.companyControl.value,
      idUser: this.userControl.value, // Aggiungi filtro utenti
      startDate: this.startDateControl.value
        ? this.getFormattedDate(this.startDateControl.value)
        : null, // Assicura che la data venga formattata correttamente
    };

    this.checkinService.getCheckinsWithPagination(filters, this.pageInit, this.pageSize).subscribe({
      next: (response: any) => {
        this.dataSource = new MatTableDataSource(response.data); // Ricrea la `dataSource`
        this.totalRows = response.count; // Imposta il numero totale
        // this.dataSource.paginator = this.paginator; // Ricollega il paginator dopo l'aggiornamento
        this.libService.unlockPage();
      },
      error: () => {
        this.toastr.error('Errore nel caricamento dei check-in.', 'Errore');
        this.libService.unlockPage();
      }
    });
  }
  // Metodo per formattare la data correttamente senza alterazioni di timezone
  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`; // Restituisce solo la parte di data
  }

  // Metodo chiamato al cambio di pagina
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize; // Aggiorna la dimensione della pagina
    this.currentPage = event.pageIndex; // Aggiorna l'indice corrente
    this.pageInit = this.currentPage * this.pageSize;

    this.loadCheckins(); // Carica i dati con i nuovi parametri
  }

  updateUserData() {
    console.log('Dati aggiornati durante il sort');
    this.dataSource.data = this.dataSource.filteredData;
  }

  verifyCheckin(checkin: any) {
    // Recupera il primo blockchainDetail con recordType = CHECKIN
    const blockchainDetail = checkin.blockchainDetails.find(
      (detail: any) => detail.recordType === 'CHECKIN'
    );

    // Controlla se il dettaglio è disponibile e recupera il txid
    if (!blockchainDetail || !blockchainDetail.txid) {
      this.toastr.error('Non è stato possibile recuperare il txid per questo check-in.', 'Errore');
      return;
    }
    const transactionId = blockchainDetail.txid;

    this.armilisService.transactionVerify(transactionId).subscribe({
      next: async (result) => {
        const hashFromBlockchain = result.txbody.hsh; // Hash dalla blockchain

        // Recupera i DPI associati al checkin
        const dpiRows = checkin.rows;

        // Calcola l'hash ricalcolato
        const recalculatedHash = await this.calculateFinalHash(dpiRows);

        if (hashFromBlockchain === recalculatedHash) {
          this.toastr.success('Il check-in è integro!', 'Verifica completata');
        } else {
          this.toastr.error('Il check-in è stato modificato!', 'Verifica fallita');
        }
      },
      error: () => {
        this.toastr.error('Errore nella verifica del check-in.', 'Errore Blockchain');
      }
    });
  }

  async calculateFinalHash(rows: any[]): Promise<string> {
    const hashes: string[] = [];

    console.log('Inizio calcolo hash per le righe');
    // Calcola l'hash di ogni riga
    for (const row of rows) {
      const formattedDate = this.formatDate(row.dataOra); // Assicura il formato corretto per la data
      const rowString = `${row.id}|${row.idHeader}|${row.idRelDpi}|${row.idStatus}|${row.idCategory}|${row.idHeaderDistinta}|${row.declaredQuantity}|${row.minimumQuantity}|${formattedDate}|${row.dpiName}|${row.categoryDPI}`;
      console.log('Stringa della riga:', rowString);

      const rowHash = await this.hashString(rowString); // Calcola l'hash per ogni riga
      console.log('Hash calcolato per la riga:', rowHash);

      hashes.push(rowHash);
    }

    console.log('Elenco degli hash delle righe (non ordinato):', hashes);

    // Ordina gli hash e concatenali
    hashes.sort();
    console.log('Elenco degli hash delle righe (ordinato):', hashes);

    const concatenatedHashes = hashes.join('');
    console.log('Hash concatenati:', concatenatedHashes);

    // Calcola l'hash finale
    const finalHash = await this.hashString(concatenatedHashes);
    console.log('Hash finale calcolato:', finalHash);

    return finalHash;
  }

  async hashString(input: string): Promise<string> {
    console.log('Inizio calcolo hash per la stringa:', input);

    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashHex = Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');

    console.log('Hash generato:', hashHex);
    return hashHex;
  }

  formatDate(dateString: string): string {
    // Utilizzo diretto della stringa ISO per evitare spostamenti di fuso orario
    const parts = dateString.split(/[-T:.]/); // Dividi la stringa nei suoi componenti
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const hours = parts[3];
    const minutes = parts[4];
    const seconds = parts[5];

    // Ricrea la data nel formato desiderato senza alterazioni di fuso orario
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }




  // Metodo chiamato al click del pulsante "Cerca"
  onSearchClick(): void {
    this.currentPage = 0; // Reset della pagina
    this.loadCheckins(); // Esegui la ricerca con i filtri
  }


  loadCountries(): void {
    const countriesUser = this.userSessionService.getCountries();
    this.countryService.index().pipe(
      tap((response: any) => {
        this.countries = response
          .filter((country: any) => countriesUser.includes(country.isoCode))
          .map((country: any) => ({
            id: country.isoCode,
            name: country.name || country.isoCode,
          }));
      })
    ).subscribe();
  }

  loadSocieties(): void {
    this.companyService.getSocietiesAndCountries().subscribe((response: any) => {
      this.societies = this.removeDuplicateSocieties(
        response.map((society: any) => ({
          id: society.societyId,
          name: society.societyName,
          country: society.societyCountry,
        }))
      );
      this.filteredSocieties = [...this.societies];
    });
  }

  loadCompanies(): void {
    this.companyService.getSocietiesAndCountries().subscribe((response: any) => {
      this.companies = this.removeDuplicateCompanies(
        response.map((company: any) => ({
          id: company.companyId,
          name: company.companyName,
          societyId: company.societyId,
        }))
      );
      this.filteredCompanies = [...this.companies];
    });
  }

  filterSocietiesByCountry(selectedCountries: string | string[]): void {
    this.societyControl.reset(); // Resetta il controllo delle società
    this.companyControl.reset(); // Resetta il controllo delle sedi

    // Se il filtro country è vuoto o nullo, mostra tutte le società
    if (!selectedCountries || (Array.isArray(selectedCountries) && selectedCountries.length === 0)) {
      this.filteredSocieties = [...this.societies]; // Mostra tutte le società
      this.filteredCompanies = [...this.companies]; // Mostra tutte le sedi
      return;
    }

    // Filtra le società in base ai paesi selezionati
    const countryArray = Array.isArray(selectedCountries) ? selectedCountries : [selectedCountries];
    this.filteredSocieties = this.societies.filter((society: any) =>
      countryArray.includes(society.country)
    );

    // Reset delle sedi perché dipendono dalle società
    this.filteredCompanies = [];
  }


  filterCompaniesBySociety(selectedSocieties: any): void {
    this.companyControl.reset(); // Resetta il controllo delle sedi

    // Se il filtro società è vuoto o nullo, mostra tutte le sedi
    if (!selectedSocieties || (Array.isArray(selectedSocieties) && selectedSocieties.length === 0)) {
      this.filteredCompanies = [...this.companies]; // Mostra tutte le sedi
      return;
    }

    // Filtra le sedi in base alle società selezionate
    const societyArray = Array.isArray(selectedSocieties) ? selectedSocieties : [selectedSocieties];
    this.filteredCompanies = this.companies.filter((company: any) =>
      societyArray.includes(company.societyId)
    );
  }


  filterSocieties(searchTerm: string): void {
    const lowerTerm = searchTerm.toLowerCase();
    this.filteredSocieties = this.societies.filter((society) =>
      society.name.toLowerCase().includes(lowerTerm)
    );
  }

  filterCompanies(searchTerm: string): void {
    const lowerTerm = searchTerm.toLowerCase();
    this.filteredCompanies = this.companies.filter((company) =>
      company.name.toLowerCase().includes(lowerTerm)
    );
  }

  // Metodo per rimuovere duplicati dalle società
  removeDuplicateSocieties(societies: any[]): any[] {
    return societies.filter(
      (society, index, self) => index === self.findIndex((s) => s.id === society.id)
    );
  }

  // Metodo per rimuovere duplicati dalle companies
  removeDuplicateCompanies(companies: any[]): any[] {
    return companies.filter(
      (company, index, self) => index === self.findIndex((c) => c.id === company.id)
    );
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

  resetFilters(): void {
    // Resetta i controlli dei filtri
    this.countryControl.reset();
    this.societyControl.reset();
    this.companyControl.reset();
    this.societySearchControl.reset();
    this.companySearchControl.reset();
    this.userControl.reset();
    this.userSearchControl.reset();
    this.startDateControl.reset(); // Resetta il controllo della data


    // Ripristina le liste filtrate alle condizioni originali
    this.filteredSocieties = [...this.societies];
    this.filteredCompanies = [...this.companies];

    // Esegui nuovamente la ricerca con i filtri resettati
    this.onSearchClick();
  }
  // Metodo per caricare gli utenti
  getUsers() {
    this.userSessionService.getVisibilities().subscribe(
      (userdata) => {
        const idSediCurrentUser = userdata.UsersCompaniesRel.map((company: any) => company.id);
        this.userSessionService.getNamesBySedeAppartenenzaIds(idSediCurrentUser).subscribe(
          (operatorNames: any) => {
            this.users = operatorNames;
            this.filteredUsers = [...this.users];
          },
          (err) => {
            console.error('Errore nel recupero dei nomi:', err);
          }
        );
      },
      (err) => {
        console.error('Errore nel recupero delle visibilità:', err);
      }
    );
  }

  filterUsers(searchTerm: string): void {
    const lowerTerm = searchTerm.toLowerCase();
    this.filteredUsers = this.users.filter((user) =>
      user.name.toLowerCase().includes(lowerTerm)
    );
  }

  onKeydown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è la barra spaziatrice
    if (event.code === 'Space' || event.key === ' ') {
      event.stopPropagation(); // Ferma la propagazione dell'evento
    }
  }
  
}
