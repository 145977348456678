import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { saveAs } from "file-saver";
import { DocumentType } from 'src/app/models/document';
import { DocumentService } from 'src/app/services/document.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';
import { IframeModal } from '../../iframe-modal/iframe-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserSessionService } from 'src/app/services/user-session.service';

@Component({
  selector: 'app-file-document-type-table',
  templateUrl: './file-document-type-table.component.html',
  styleUrls: ['./file-document-type-table.component.scss']
})
export class FileDocumentTypeTableComponent implements OnInit {

  @Input() documentTypes: DocumentType[] | null
  @Input() order: any | null

  @Output() selectFileClickedEvent = new EventEmitter();

  columns: DatatableColumn<DocumentType>[] = [
    {
      name: "documentType",
      title: this.translate.get('LABEL.DocumentType'),
      cssClass: documentType => ['column-padding'],
      value: documentType => this.translate.instant('practice_workflow_manager.document' + documentType.code)
    },
    {
      name: "min",
      title: this.translate.get('LABEL.DocumentTypeMin'),
      cssClass: documentType => ['column-padding'],
      value: documentType => documentType.min
    },
    {
      name: "max",
      title: this.translate.get('LABEL.DocumentTypeMax'),
      cssClass: documentType => ['column-padding'],
      value: documentType => documentType.max
    },
    {
      name: "total",
      title: this.translate.get('LABEL.DocumentTypeTotal'),
      cssClass: documentType => ['column-padding'],
      value: documentType => documentType.total
    }
  ]

  actions: DatatableAction<DocumentType>[] = [
    {
      label: of(''),
      icon: subject => {
        return 'upload_file'
      },
      // 'upload',
      onClick: documentType => this.selectFileClicked(documentType),
      color: 'primary',
      disabled: (documentType: any) => {
        // Di default il campo di upload non è diasttivato
        let status: any = false

        // Se è presente il valore massimo verifico se il totale è compreso tra i 2 limiti
        if (![undefined, null].includes(documentType.max)) {

          if (documentType.total >= documentType.max) status = true

        } else if (![undefined, null].includes(documentType.min)) {

          //if(documentType.total >= documentType.min) status = true

        }

        return status
      }
    },
    {
      label: of(''),
      icon: subject => {
        return 'file_open'
      },
      onClick: documentType => this.downloadTemplate(documentType),
      color: 'primary',
      disabled: documentType => (documentType.templateDocument || (!(documentType.urlDocComposition == null) && (documentType.urlDocComposition.length > 0))) ? false : true
    }
  ];

  displayedColumns: string[] = ['documentType', 'min', 'max', 'total'];


  constructor(
    public translate: TranslateService,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private userSessionService: UserSessionService,
  ) { }


  ngOnInit(): void {
  }

  selectFileClicked(documentType: DocumentType) {
    this.selectFileClickedEvent.next(documentType)
  }

  downloadTemplate(documentType: DocumentType) {
    console.log('[DocumentTypeTableComponent.downloadTemplate] - documentType %o', documentType.urlDocComposition);
    if (!(documentType.urlDocComposition == null) && (documentType.urlDocComposition.length > 0)) {
      console.log('[DocumentTypeTableComponent.downloadTemplate] - callDocumentComposition %o', documentType.urlDocComposition);
      let payLoad = {
        numeroPratica: this.order.code,
        versionePratica: this.order.version.toString()
      }
      console.log(documentType.urlDocComposition)
      let currentUser = this.userSessionService.getState('user');

      const dialogRef = this.dialog.open(IframeModal, {
        width: '1600px',
        data: {
          urlDocComposition: documentType.urlDocComposition,
          practiceCode: this.order.code,
          token: currentUser.email
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
       
      });

      /*
      this.documentService.callDocComp(documentType.urlDocComposition, payLoad).subscribe(res => {
        console.log('[DocumentTypeTableComponent.downloadTemplate.] call callDocComp payload %o response %o', payLoad, res['document']);
        let str = res['document'];
        var byteCharacters = atob(str);
        var bytes = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          bytes[i] = byteCharacters.charCodeAt(i);
        }
        var byteCharactersN = new Uint8Array(bytes);

        const blob = new Blob([byteCharactersN], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = documentType.code + ".docx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

      });
      */
    }
    else {
      if (documentType.templateDocument) {
        this.documentService.getSignedUrl(documentType.templateDocument.awsPath!).subscribe({
          next: (signedUrl: string) => {
            window.open(signedUrl)
          },
          error: (err: any) => {
            console.log(err);
            this.toastr.error(err.message)
          }
        })
      }
    }
  }



}
