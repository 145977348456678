import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, map, tap, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Company } from 'src/app/models/company';
import { Status } from 'src/app/models/status';
import { SubjectType } from 'src/app/models/subjectType';
import { User } from 'src/app/models/user';
import { WorkType } from 'src/app/models/workType';
import { BuildingService } from 'src/app/services/building.service';
import { ChannelService } from 'src/app/services/channel.service';
import { CompanyService } from 'src/app/services/companyService';
import { FTIndexAllService } from 'src/app/services/ftindex-all.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { WorkTypeService } from 'src/app/services/worktype.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { ItemCategoryService } from 'src/app/services/itemCategory.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { CountryService } from 'src/app/services/country.service';
import { LibService } from 'src/app/services/libService'
import { ContactReasonService } from 'src/app/services/contact-reason.service'
import { OriginService } from 'src/app/services/origin.service';
import { ComponentService } from 'src/app/services/component.service';
import { UserDataService } from 'src/app/services/users.service';
import { RoleService } from 'src/app/services/role.service';
import { ComplaintTypesService } from 'src/app/services/complaint-types.service';
import { SolictReasonsService } from 'src/app/services/solict-reasons.service';
import { MarketingNoteService } from 'src/app/services/marketingNote.service';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() types: any
  @Input() origins: any;
  @Input() edit: boolean;
  @Input() isNew: boolean;
  @Input() components: any;
  @Input() countryCode: any;
  @Output() countryCodeChange = new EventEmitter<string>(); // Evento per inviare al genitore


  attributes: any;

  componentName: any = 'generic_request_form'


  statusTemporary: Status
  controlTypes = ['Privato', 'Amministratore'];
  hideLegalEntity: boolean = true;
  workTypes: any;
  worktypes: any;
  wt: any;
  // companies$: any = this.itemCategoryService.index();
  businessLines: any = []
  operatorList: any = []
  points: any = []


  countries: any = [];
  societies: any = [];
  users: any = [];
  marketingNotes: any = [];

  contactReasons$ = this.contactReasonService.getAll()


  companies: any;

  loggedUser: any;

  complaintTypes: any;
  complaintTypes$ = this.complaintTypesService.getAllComplaintTypes();

  solicitReasons: any;
  solicitReasons$ = this.solictReasonsService.getAllSolicitReasons();


  origins$ = this.originService.getAllOrigins();

  initPoints() {
    let countryCode = this.form?.get('countryCode')?.value
    let attributes = {
      countryCode: countryCode
    }
    this.companyService.points(attributes).subscribe((res: any) => {
      this.points = res.sort((a: any, b: any) => a.name.localeCompare(b.name));
    })
  }

  initMarketingNotes() {
    this.marketingNoteService.index().subscribe((res: any) => {
      res.forEach((marketingNote: any) => {
        let structure: any = {
          id: marketingNote?.id,
          code: marketingNote?.id,
          disabled: marketingNote?.disabled,
          translations: {}
        }
        marketingNote?.translationGroup?.translations.forEach((translation: any) => {
          structure.translations[translation.languageCode] = translation.text
        })

        if (marketingNote.request) this.marketingNotes.push(structure)
      })

    })

  }

  loggedUser$ = this.userSessionService.getUser(this.authService.user().email).pipe(
    map((val) => this.loggedUser = val),
    tap(val => {
      this.form.get('insertionOperatorRole')?.setValue(val[0].UsersProfilesRel?.find(profile => profile.name === 'BackOffice')?.name)
    }));


  contactChannels$: any

  companies$ = this.companyService.indexWithBusinessLines().pipe(
    map((companies: any[]) => {
      return companies.sort((a, b) => a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      this.companies = [...new Set(companies)]
      this.companies.push({ id: 0, code: undefined, name: '' })
    })
  );

  workTypes$ = this.workTypeService.getAllWorkTypes()//.pipe(
  // map(workTypes => {
  //   return workTypes.filter(workType => workType.codLanguage?.toLocaleLowerCase() === this.form.get('building.company')?.value)//this.translate.currentLang
  // })
  //)

  constructor(
    public subjectService: SubjectService,
    public buildingService: BuildingService,
    private companyService: CompanyService,
    private workTypeService: WorkTypeService,
    private statusService: StatusService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private channelService: ChannelService,
    private workflowService: WorkflowService,
    private itemCategoryService: ItemCategoryService,
    private userSessionService: UserSessionService,
    private countryService: CountryService,
    private libService: LibService,
    private contactReasonService: ContactReasonService,
    private originService: OriginService,
    private componentService: ComponentService,
    private userService: UserDataService,
    private roleService: RoleService,
    private complaintTypesService: ComplaintTypesService,
    private solictReasonsService: SolictReasonsService,
    private marketingNoteService: MarketingNoteService,
  ) {

  }

  compare(a: any, b: any) {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  }

  getCompaniesbyBusinessLineId(businessLineId: any = null) {
    let businessLineIdValue = businessLineId || this.form.get('businessLineId')?.value
    this.countryService.getCompaniesbyBusinessLineId(businessLineIdValue).subscribe((response) => {
      console.log(response)
      this.companies = response.sort(this.compare)
      let companyId: any = this.form.get('companyId')?.value
      let currentCompany: any = this.companies.find((company: any) => company.id == companyId)

      // Setta la lista degli operatori se il country è settato
      if (currentCompany) {
        this.form?.get('companyCode')?.setValue(currentCompany.code)
        this.loggedUser$.subscribe((res: any) => {
          this.handleUsers();
        })
      }

    });
  }

  setCountryCodeAndOperators() {

  }

  getCurrentLanguage() {
    return this.userSessionService.getCurrentLanguage()?.toUpperCase() || 'IT';
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes - generic form')
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'form': {
            console.log(changes[propName].currentValue)
            let form = changes[propName].currentValue
            let id = form.get('id')?.value
            let businessLineId = form.get('businessLineId')?.value
            let companyId = form.get('companyId')?.value
            if (id != null) {
              this.initComplaintTypes()
              this.initSolicitReasons()
              if (businessLineId && [null, undefined].includes(this.worktypes)) this.initWorktypes(businessLineId)
              if (businessLineId && [null, undefined].includes(this.companies)) this.getCompaniesbyBusinessLineId(businessLineId)
            } else {
              this.initCountries()
            }
          }
        }
      }
    }

    this.addValidations()
    this.setEditabilityOfFields()

    if (this.types !== null && this.types !== undefined)
      this.types.sort((a: any, b: any) => a.description!.toUpperCase() <= b.description!.toUpperCase() ? -1 : 1)
    if (this.origins !== null && this.origins !== undefined)
      this.origins.sort((a: any, b: any) => a.origin!.toUpperCase() <= b.origin!.toUpperCase() ? -1 : 1)
    console.log("WORKTYPES --> ", this.wt);





  }

  initWorktypes(businessLineId: any = null) {
    console.log('INIT WORKTYPES')
    let businessLineIdValue = businessLineId || this.form.get('businessLineId')?.value
    console.log(businessLineIdValue)
    this.workTypeService.getAllWorkTypes().subscribe((response: any) => {
      console.log(response)
      this.worktypes = response.filter((worktype: any) => worktype.businessLineId == businessLineIdValue)
    })
  }

  initComplaintTypes() {
    console.log('INIT COMPLAINT TYPES')
    this.complaintTypes$.subscribe({
      next: (res) => {
        this.complaintTypes = res;
      },
    })
  }

  initSolicitReasons() {
    console.log('INIT SOLICIT REASONS')
    this.solicitReasons$.subscribe({
      next: (res) => {
        this.solicitReasons = res;
      },
    })
  }

  initCountries() {
    console.log('INIT COUNTRIES')
    this.countries = this.userSessionService.getCountries()
    if (this.countries.length == 1) this.form.get('countryCode')?.setValue(this.countries[0])
  }

  initSocieties(countryCode: any) {
    console.log('INIT SOCIETIES')
    this.countryService.determinesConfiguration(countryCode).subscribe(
      (response) => {
        response.forEach((configuration: any) => {

          let tmpSociety: any = { id: configuration.societyId, code: configuration.societyCode }
          let societyIds = this.societies.map((society: any) => society.id)

          if (!societyIds.includes(tmpSociety.id)) this.societies.push(tmpSociety)
        })
      },
      (err: any) => { console.log(err) }
    );
  }

  initBusinessLines(countryCode: any, attributes: any) {
    console.log('INIT BUSINESS LINES')
    this.countryService.determinesConfiguration(countryCode, attributes).subscribe(
      (response) => {
        console.log(response)
        response.forEach((configuration: any) => {

          let tmpbusinessLine: any = { id: configuration.businessLineId, code: configuration.businessLineCode }
          let businessLineIds = this.businessLines.map((businessLine: any) => businessLine.id)

          if (!businessLineIds.includes(tmpbusinessLine.id)) this.businessLines.push(tmpbusinessLine)
        })
      },
      (err: any) => { console.log(err) }
    );
  }

  ngOnInit(): void {
    const countryCode = this.form?.get('countryCode')?.value;

    if (countryCode) {
      console.log('Valore countryCode inviato:', countryCode);
      this.countryCodeChange.emit(countryCode); // Emetti il valore una sola volta
    } else {
      console.error('Il valore di countryCode è undefined o nullo');
    }

    this.form?.get("companyCode")?.valueChanges.subscribe(x => {
      this.loggedUser$.subscribe((res: any) => {
        this.handleUsers();
      })
    })
    this.contactChannels$ = this.channelService.getAllChannels(undefined, this.form?.get('countryCode')?.value);
    this.initMarketingNotes()
    this.initPoints()
  }
  

  checkIfPoints() {
    let contactChannel = this.form?.get('contactChannel')?.value
    let origin = this.form?.get('origin')?.value
    let status = (contactChannel == 'point') || (origin == 'Point')

    if (status) {
      if (this.points.length == 0) status = false // se non ci sono points non mostro la select points
      if (this.points.length > 0) this.form!.get('pointId')?.addValidators(Validators.required); // aggiungo la validazione se è presente almeno un points
    } else {
      this.form!.get('pointId')?.clearValidators();
      this.form!.get('pointId')?.setValue(null);
    }


    return status
  }


  resetFormValues(items: any = []) {

    items.forEach((item: any) => {
      this.form.get(item.formField)?.setValue(null) // Reset valore form
      eval(`this.${item.selectCollection} = []`) // Reset valori select
    })

  }

  getSocieties(event: any) {
    console.log('getSocieties')
    this.libService.lockPage('');


    this.resetFormValues([
      { formField: 'societyId', selectCollection: 'societies' },
      { formField: 'businessLineId', selectCollection: 'businessLines' },
    ]) // Reset form values

    this.countryService.determinesConfiguration(event.value).subscribe(
      (response) => {
        console.log(response)
        response.forEach((configuration: any) => {

          let tmpSociety: any = { id: configuration.societyId, code: configuration.societyCode }
          let societyIds = this.societies.map((society: any) => society.id)

          if (!societyIds.includes(tmpSociety.id)) this.societies.push(tmpSociety)
        })

        this.libService.unlockPage();
      },
      (err: any) => { console.log(err) }
    );
  }

  getBusinessLines(event: any) {
    console.log('getBusinessLines')
    this.libService.lockPage('');


    this.resetFormValues([
      { formField: 'businessLineId', selectCollection: 'businessLines' }
    ]) // Reset form values

    let countryCode = this.form.get('countryCode')?.value
    let societyId = this.form.get('societyId')?.value

    this.countryService.determinesConfiguration(countryCode, { societyId: societyId }).subscribe(
      (response) => {
        console.log(response)
        response.forEach((configuration: any) => {

          let tmpbusinessLine: any = { id: configuration.businessLineId, code: configuration.businessLineCode }
          let businessLineIds = this.businessLines.map((businessLine: any) => businessLine.id)

          if (!businessLineIds.includes(tmpbusinessLine.id)) this.businessLines.push(tmpbusinessLine)
        })

        this.libService.unlockPage();
      },
      (err: any) => { console.log(err) }
    );
  }

  setContactReasonId(event: any) {
    this.form.get('contactReasonId')?.setValue(event.value.id)
  }

  disabledSocietyIdSelect() {
    return this.form.get('countryCode')?.value == null && this.societies.length == 0
  }

  disabledBusinessLineIdSelect() {
    return this.form.get('societyId')?.value == null && this.businessLines.length == 0
  }

  ngOnDestroy(): void {
    this.form.get('subject')?.reset()
    this.form.get('building')?.reset()
  }

  selSubject(event: any) {
    console.log('Selected: ', event);

    switch (event.type) {
      case 'Edificio':
        this.clearForm('building')
        this.form.get('building')?.patchValue(event)
        break;
      default:
        this.clearForm('subject')
        this.form.get('subject')?.patchValue(event)
        break;
    }
  }

  clearForm(formName: string) {
    //set status provvisorio per subject e building
    this.form.get(formName)?.reset()
    this.form.get(formName)?.get('status')?.setValue(this.statusTemporary)
  }

  get addressSubjectValid() {
    let streetName = this.form.get('subject')?.get('streetName')?.valid
    let streetNumber = this.form.get('subject')?.get('streetNumber')?.valid
    let zip = this.form.get('subject')?.get('zip')?.valid
    let city = this.form.get('subject')?.get('city')?.valid
    let state = this.form.get('subject')?.get('state')?.valid
    let country = this.form.get('subject')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get addressBuildingValid() {
    let streetName = this.form.get('building')?.get('streetName')?.valid
    let streetNumber = this.form.get('building')?.get('streetNumber')?.valid
    let zip = this.form.get('building')?.get('zip')?.valid
    let city = this.form.get('building')?.get('city')?.valid
    let state = this.form.get('building')?.get('state')?.valid
    let country = this.form.get('building')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get subjectForm() {
    return this.form.controls['subject'] as UntypedFormGroup
  }

  get buildingForm() {
    return this.form.controls['building'] as UntypedFormGroup
  }

  compareFn(o1: WorkType, o2: WorkType) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  private setCompanyLocationAndResponsibles(companyCode: string) {
    if (companyCode && (this.companies && this.companies.length > 0)) {
      let companyLocal = this.companies.find((company: any) => company.code === companyCode)
      try {
        this.form.get('companyLocation')?.setValue(companyLocal!.CompaniesSocietiesRel![0].name)
      } catch (error) {
        this.toastr.error(this.translate.instant('ERROR.COMPANYLOC'))
      }

      try {
        let responsibles = companyLocal!.CompaniesUsersRel!.map((user: User) => user.email)
        let responsible = responsibles.includes(this.authService.user().name) ? this.authService.user().name : responsibles[0]
        this.form.get('responsible')?.setValue(responsible)
      } catch (error) {
        this.toastr.error(this.translate.instant('ERROR.COMPANYRESP'))
      }
    }
  }

  getAttributes(field: any) {
    return this.componentService.getAttributes(field, this.attributes)
  }

  checkVisibility(field: any) {
    return this.componentService.checkVisibility(field, this.attributes)
  }

  verifyDeactivation(field: any) {
    return !this.componentService.checkEditability(field, this.attributes)
  }

  mandatoryVerification(field: any) {
    return this.componentService.mandatoryVerification(field, this.attributes)
  }

  addValidations() {
    //console.log('addValidation - Generic')
    if (this.attributes) {
      for (const [key, value] of Object.entries(this.attributes)) {
        if (this.mandatoryVerification(key)) this.form!.get(key)?.addValidators(Validators.required);
      }
    }

  }

  setEditabilityOfFields() {
    console.log('setEditabilityOfFields - Generic')
    if (this.attributes) {
      for (const [key, value] of Object.entries(this.attributes)) {
        let status = this.componentService.checkEditability(key, this.attributes)
        status ? this.form!.get(key)?.enable() : this.form!.get(key)?.disable()
      }
    }
  }

  getOperatorList(event: any) {
    let companyId: any = event.value
    let currentCompany: any = this.companies.find((company: any) => company.id == companyId)
    this.form.get('companyCode')?.setValue(currentCompany.code)
    this.handleUsers()
  }

  handleUsers() {
    console.log('handleUsers')
    let acceptedCompanies: Array<any> = [];
    let operator = this.form.get('assignmentOperator')?.value;
    let companyCode: any = this.form.get('companyCode')?.value;
    if (companyCode) {
      acceptedCompanies.push(companyCode)

      let userCompanies = this.loggedUser[0].UsersCompaniesRel.map((comp: any) => comp.code)
      console.log("ACCEPTED COMPANIES (generic form) --> ", acceptedCompanies);

      // SOSTITUIRE CON CHIAMATA PER OTTENERE USERS DALLA COMPANY CODE
      acceptedCompanies.length > 0 ?
        this.userService.getByCompanyCode(acceptedCompanies).subscribe((comps: any) => {
          console.log("USERS BY Company Code -->", comps);
          let pippo = comps.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(12) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
          console.log("USERS FILTRATI PER PROFILI --> ", pippo);
          try {
            this.operatorList = [... new Set(pippo.map((tag: any) => tag))];
            console.log(this.operatorList)
          } catch (err) {
            console.log(err)
          }

          userCompanies.includes(acceptedCompanies[0]) ? this.operatorList.push(this.loggedUser[0]) : undefined;
          const index = this.operatorList.indexOf(this.loggedUser[0])
          index > -1 ? this.operatorList.splice(index, 1) : undefined;
          this.roleService.getMainRole(this.loggedUser);
          this.loggedUser.role !== 'BackOffice' ? this.operatorList.push(this.loggedUser[0]) : null;
          this.cdr.detectChanges();

        }) : undefined;
    }
    this.form.patchValue({ assignmentOperator: operator })

  }

  setAttributes() {
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) this.setAttributes()
    if (status) this.addValidations()
    return status
  }
}
